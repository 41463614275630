import React from 'react'
import breadcrumbsImg from "../Assert/breadcrumbs-bg.jpg";
import { Link } from 'react-router-dom';


const Breadcrumbs = ({pageName, slugs}) => {
  return (
    <>
        <div className="rs-breadcrumbs">
  <img src={breadcrumbsImg} alt="Blossom Playgroup" />
  <div className="container">
    <div className="breadcrumbs-content">
      <h1 className="title">{pageName} </h1>
      <div className="page-path text-center">
        <ul>
          <li>
          <Link to='/'>
          Home
          </Link>
          </li>
          <li>{slugs}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default Breadcrumbs