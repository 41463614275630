import React, { useState,useLayoutEffect } from 'react';
import "../Css/Gallery.css";
import Breadcrumbs from "./Breadcrumbs";
import Tabs from "./Tabs";
import Items from "./Items";
import GalleryData from '../Components/GalleryData.jsx';
import MetaTags from 'react-meta-tags';

const SummerCamps = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

const [data, setData] = useState(GalleryData)
console.log(data)
// Store Category in CategoryData 
const categoryData = GalleryData.map((value) => {
    return value.Category
});
////////////

const tabsData = ["all", ...new Set(categoryData)];


// for all categoryData set 
const filterCategory = (Category) => {
    if (Category == "all") {
        setData(GalleryData)
        return;
    }
    const filteredData = GalleryData.filter((value) => {
        return value.Category == Category;
    })

    setData(filteredData);

}

  return (
    <>
    <MetaTags>
      <title>Kids Summer Camp in Borivali West | Blossom Preschool</title>
        <meta title="Kids Summer Camp in Borivali West | Blossom Preschool" />
        <meta
          name="description"
          content="Join our exciting Kids Summer Camp in Borivali West at Blossom Preschool for a memorable blend of fun and learning!"
        />
        <meta
          name="keywords"
          content="Blossom Play-group Borivali,Play Group Nursery and Daycare in Borivali,Daycare Center in Borivali,Play Group Nursery in Borivali,Best Nursery in Borivali,Preschool in Borivali,Kids Summer Camp in Borivali West,Play Group Nursery near Borivali"
        />
        <link rel="canonical" href="https://justmirrorsalon.in/top-salon-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Kids Summer Camp in Borivali West | Blossom Preschool" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://justmirrorsalon.in/top-salon-andheri"/>
        <meta property="og:description" content="Join our exciting Kids Summer Camp in Borivali West at Blossom Preschool for a memorable blend of fun and learning!" />
        <meta property="og:image" content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
    
      <Breadcrumbs pageName="Kids Summer Camp in Borivali West" slugs="SUMMER CAMPS" />

      {/* <section>
        <div id="kinder-schedule" className="kinder-schedule ">
          <div className="container">
            <div className="sec-title text-center">
              <h2 className="section-title mb-10 kinder-pulm">
                Summer Schedule
              </h2>
              <h4 className="section-subtitle">ADVENTURE SPORTS</h4>
            </div>
            <div id="rs-classes" className="rs-classes  pb-70">
              <div className="container">
                <div className="row">
                  
                  {summerCamp
                    .filter((v) => v.category === "ADVENTURE SPORTS")
                    .map((v, i) => (
                      <div className="col-lg-4 col-md-6 col-sm-6 mb-30">
                        <div className="classes-grid">
                          <div className="top-part">
                            <img src={v.img} alt="image" />
                          </div>
                          <div className="middle-part">
                           
                            <h3 className="title">
                              <a className="kinder-pulm" href="#">
                                {v.tilte}
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div id="kinder-schedule" className="kinder-schedule pb-100">
          <div className="container">
            <div className="sec-title text-center">
              <h2 className="section-title mb-10 kinder-pulm">
               Summer Of Camps Glimps
              </h2>
              <h4 className="section-subtitle">FUN UNLIMITED</h4>
            </div>
            <div id="rs-classes" className="rs-classes  pb-70">
              <div className="container">
                <div className="row">
                  {summerCamp
                    .filter((v) => v.category === "FUN UNLIMITED")
                    .map((v, i) => (
                      <div className="col-lg-4 col-md-6 col-sm-6 mb-30">
                        <div className="classes-grid">
                          <div className="top-part">
                            <img src={v.img} alt="image" />
                          </div>
                          <div className="middle-part">
                         
                            <h3 className="title">
                              <a className="kinder-pulm" href="#">
                                {v.tilte}
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className='hidden-content'>
      Kids Summer Camp in Borivali West is the ultimate fun camp for the kids  Our camp is designed to ignite the spark of curiosity and adventure in young minds while providing a safe and engaging environment for children to thrive.
At Kids Summer Camp in Borivali West, we offer a wide range of exciting activities tailored to suit every child's interests. From arts and crafts to sports and outdoor adventures, there's something for everyone. Our experienced and enthusiastic instructors ensure that each child receives personalized attention and encouragement to explore their talents and discover new passions.
Located in the heart of Borivali West, our camp provides easy access for families in the area. With state-of-the-art facilities and ample outdoor space, children have the freedom to play, learn, and grow in a nurturing environment.
Join us this summer for an unforgettable experience filled with laughter, friendship, and endless possibilities. Enroll your child in Kids Summer Camp in Borivali West today and watch them flourish as they create memories that will last a lifetime. Let's make this summer the best one yet!


      </div>
      <div className='Gallery-Section-2'>
                <div className='Gallery-Card'>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                                <Items data={data} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

    </>
  );
};

export default SummerCamps;
