import React from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Breadcrumbs from "./Breadcrumbs";
import { Swiper, SwiperSlide } from 'swiper/react';
import MetaTags from 'react-meta-tags';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import  PlayGroupData  from "../Components/PlayGroupData";
const Playgroup = () => {
  return (
    <>
    <MetaTags>
      <title>Play Group Nursery in Borivali | Blossom Preschool</title>
        <meta title="Play Group Nursery in Borivali | Blossom Preschool" />
        <meta
          name="description"
          content="Discover dynamic learning at Play Group Nursery in Borivali. Blossom Preschool fosters growth, creativity, and joy in every child."
        />
        <meta
          name="keywords"
          content="Blossom Play-group Borivali,Play Group Nursery and Daycare in Borivali,Daycare Center in Borivali,Play Group Nursery in Borivali,Best Nursery in Borivali,Preschool in Borivali,Kids Summer Camp in Borivali West,Play Group Nursery near Borivali"
        />
        <link rel="canonical" href="https://justmirrorsalon.in/top-salon-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Play Group Nursery in Borivali | Blossom Preschool" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://justmirrorsalon.in/top-salon-andheri"/>
        <meta property="og:description" content="Discover dynamic learning at Play Group Nursery in Borivali. Blossom Preschool fosters growth, creativity, and joy in every child." />
        <meta property="og:image" content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
    <Breadcrumbs pageName="Play Group Nursery in Borivali" slugs="Play Ground" />
      <div id="rs-team-details" className="rs-class-details pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-sm-12 pr-25 mb-sm-30">
            <Swiper
        spaceBetween={30}
        centeredSlides={true}
        
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {PlayGroupData.map((v,i)=>(
          <SwiperSlide>
            <div className="swiperImg">
            <img src={v.img} alt="Play Group Nursery" />
            </div>
          
          </SwiperSlide>
        ))}
      </Swiper>
            </div>
            <div className="col-md-5 col-sm-12 pl-25">
              <div className="details-content-part">
                <div className="sec-title mb-30">
                  <h3 className="section-title mb-10 kinder-pulm">
                  Play Group Nursery
                  </h3>
                  <p className="section-desc">
                  Blossoms Play Group Nursery in Borivali, with locations in both Borivali West and Borivali East, is the ultimate choice for parents seeking top-notch early childhood education. Our dedicated team of experienced educators provides a secure and nurturing environment where children can thrive, ensuring safety and individualized care at all times.

                  </p>
                  <p className="section-desc">
                  At Blossoms Play Group Nursery in Borivali, we offer a comprehensive curriculum designed to foster curiosity, creativity, and a passion for learning, prioritizing the holistic development of each child. Our approach promotes physical, cognitive, and socio-emotional growth, celebrating the unique talents and abilities of every child to foster a sense of belonging and empowerment. Enroll your child at Blossoms Play Group Nursery in Borivali today and embark on a journey of discovery, growth, and limitless possibilities.

                  </p>
                </div>
                {/* <div className="row">
            <div className="col-md-6 col-sm-12 mb-30">
              <div className="item-box">
                <p className="title">Start Date</p>
                <div className="date">12 June, 2019</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mb-30">
              <div className="item-box">
                <p className="title">Year Old</p>
                <div className="date">2-5 Year</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mb-30">
              <div className="item-box">
                <p className="title">Class Size</p>
                <div className="date">15 Seats</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mb-30">
              <div className="item-box">
                <p className="title">Class durantion</p>
                <div className="date">8.00-11.00am</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mb-sm-30">
              <div className="item-box">
                <p className="title">Class Type</p>
                <div className="date">Primary</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="item-box">
                <p className="title">Pricing</p>
                <div className="date">$25/Full Day</div>
              </div>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kinder-schedule" className="kinder-schedule pb-100">
        <div className="container">
          <div className="sec-title text-center">
            <h2 className="section-title mb-10 kinder-pulm">Programs</h2>
            <h4 className="section-subtitle">Our Programs</h4>
          </div>
          <div className="text-center mb-4">
            Lorem ipsum is a placeholder text commonly used to demonstrate the
            visual form of a document or a typeface without relying on
            meaningful content. Lorem ipsum may be used as a placeholder before
            the final copy is available. Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. Lorem ipsum may be
            used as a placeholder before the final copy is available. Lorem
            ipsum is a placeholder text commonly used to demonstrate the visual
            form of a document or a typeface without relying on meaningful
            content. Lorem ipsum may be used as a placeholder before the
          </div>
          <div>
            <ul className="program-list">
              <li>
                {" "}

                able to follow instructions involving several ideas or actions.
              </li>
              <li>
                {" "}
                able to use past, present and future forms accurately when
                talking about events that have happened or are to happen in the
                future.
              </li>
              <li>
                use phonic knowledge to decode regular words and read them aloud
                accurately.
              </li>
              <li>
                Know about 2D and 3D shapes, learn to read/write/count numbers 1
                – 20, learn about size: bigger/smaller, quantity: more/less,
                capacity: empty/full.
              </li>
              <li>
                know about similarities and differences in relation to places,
                objects, materials and living things.
              </li>
              <li>
                safely use and explore a variety of materials, tools and
                techniques, experimenting with colour, design, texture, form and
                function.
              </li>
              <li>
                represent their own ideas, thoughts and feelings through design
                and technology, art, music, dance, role-play and stories.
              </li>
              <li>
                able to count reliably with numbers from 1 to 20, place them in
                order and say which number is one more or one less than a given
                number{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* -------------------------feature cards--------------------- */}
<section
  id="uid976692473851"
  className="zb-section zb-element zb-el-zionSection"
>
  <div className="zb-section__innerWrapper">
    <div className="container">
      <div className='row'>
        <div className="col-lg-4 col-12">
        <div
      id="uid976743781481"
      className="zb-column--4 text-white zb-column zb-element zb-el-zionColumn"
    >
      <h4 id="uid976743781482" className="zb-element zb-el-zionHeading">
        Facilities
      </h4>
      <div id="uid976743781483" className="zb-element zb-el-zionText">
        <ul>
          <li>A clean and safe environment</li>
          <li>Areas for indoor and outdoor play</li>
          <li>A quiet space for rest and naps</li>
          <li>Nutritious meals and snacks.</li>
          <li>Regular but flexible routines goals. </li>
          <li>Air conditioned premises</li>
          <li>Food service</li>
          <li>Separate proper sleeping arrangements</li>
          <li>Pick up and drop facility</li>
        </ul>
      </div>
    </div>
        </div>
        <div className="col-lg-4 col-12">
        <div
      id="uid976744429151"
      className="zb-column--4 text-white zb-column zb-element zb-el-zionColumn"
    >
      <h4 id="uid976744429152" className="zb-element zb-el-zionHeading">
        Features
      </h4>
      <div id="uid976744429153" className="zb-element zb-el-zionText">
        <ul>
          <li>
            {" "}
            Intellectual and physical growth oriented activities every day.{" "}
          </li>
          <li> Planned yearly holidays. </li>
          <li> Planned food and activity schedule.</li>
          <li> Calling time. </li>
          <li> Personal and individual attention.</li>
          <li> Homework Guidance.</li>
          <li> Customized Package.</li>
          <li> Educational Environment.</li>
          <li> Working hours: Mon to Sat, 8am to 8pm. </li>
        </ul>
      </div>
    </div>
        </div>
        <div className="col-lg-4 col-12">
        <div
      id="uid976692500421"
      className="zb-column--4 text-white zb-column zb-element zb-el-zionColumn"
    >
      <h4 id="uid976731746371" className="zb-element zb-el-zionHeading">
        Extra Curricular/ Other Activities
      </h4>
      <div id="uid976731823001" className="zb-element zb-el-zionText">
        <ul>
          {/* <li> Bharatnatyam</li> */}
          <li> Western Dance</li>
          <li> Story Telling</li>
          <li> Gymanstics </li>
          <li> Drawing and Craft </li>
          <li> Phonics &amp; Spell Zone</li>
          {/* <li> Group Tutions</li> */}
          <li> Hand-writing </li>
        </ul>
      </div>
    </div>
        </div>
      </div>
    </div>
    
   
    
    
  </div>
</section>
    </>
  );
};

export default Playgroup;
