import React from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Breadcrumbs from "./Breadcrumbs";
import { Swiper, SwiperSlide } from 'swiper/react';
import MetaTags from 'react-meta-tags';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import  NurceryData  from "../Components/NurceryData";
const Nursery = () => {
  return (
    <div>
      <MetaTags>
      <title>Best Nursery in Borivali | Blossom Preschool</title>
        <meta title="Best Nursery in Borivali | Blossom Preschool" />
        <meta
          name="description"
          content="Experience excellence at Blossom Preschool, Borivali's premier nursery, offering top-notch care and a nurturing environment for your child's growth."
        />
        <meta
          name="keywords"
          content="Blossom Play-group Borivali,Play Group Nursery and Daycare in Borivali,Daycare Center in Borivali,Play Group Nursery in Borivali,Best Nursery in Borivali,Preschool in Borivali,Kids Summer Camp in Borivali West,Play Group Nursery near Borivali"
        />
        <link rel="canonical" href="https://justmirrorsalon.in/top-salon-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Nursery in Borivali | Blossom Preschool" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://justmirrorsalon.in/top-salon-andheri"/>
        <meta property="og:description" content="Experience excellence at Blossom Preschool, Borivali's premier nursery, offering top-notch care and a nurturing environment for your child's growth." />
        <meta property="og:image" content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
         <Breadcrumbs pageName="Best Nursery in Borivali" slugs="Best-nursery-borivali" />
      <div id="rs-team-details" className="rs-class-details pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-sm-12 pr-25 mb-sm-30">
            <Swiper
        spaceBetween={30}
        centeredSlides={true}
        
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {NurceryData.map((v,i)=>(
          <SwiperSlide>
            <div className="swiperImg">
            <img src={v.img13} alt=' Nursery in Borivali'/>
            </div>
          
          </SwiperSlide>
        ))}
      </Swiper>
            </div>
            <div className="col-md-5 col-sm-12 pl-25">
              <div className="details-content-part">
                <div className="sec-title mb-30">
                  <h3 className="section-title mb-10 kinder-pulm">
                    Nursery
                  </h3>
                  <p className="section-desc">
                  Nestled in the vibrant community of Borivali, the Best Nursery in Borivali stands out for its unwavering dedication to nurturing young minds with excellence. Our meticulously crafted curriculum fosters exploration, learning, and growth within a stimulating environment. Here at the Best Nursery in Borivali, holistic development takes precedence, encompassing not only academic prowess but also the cultivation of creativity, emotional intelligence, and social adeptness.
                  </p>
                  <p className="section-desc">
                  Our adept team of educators employs innovative pedagogical approaches to captivate young learners and instill a lifelong passion for knowledge. With cutting-edge facilities and a secure, nurturing ambiance, parents trust the Best Nursery in Borivali with their child's formative years, confident they are in the most capable hands.

                  </p>
                </div>
                {/* <div className="row">
            <div className="col-md-6 col-sm-12 mb-30">
              <div className="item-box">
                <p className="title">Start Date</p>
                <div className="date">12 June, 2019</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mb-30">
              <div className="item-box">
                <p className="title">Year Old</p>
                <div className="date">2-5 Year</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mb-30">
              <div className="item-box">
                <p className="title">Class Size</p>
                <div className="date">15 Seats</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mb-30">
              <div className="item-box">
                <p className="title">Class durantion</p>
                <div className="date">8.00-11.00am</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 mb-sm-30">
              <div className="item-box">
                <p className="title">Class Type</p>
                <div className="date">Primary</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="item-box">
                <p className="title">Pricing</p>
                <div className="date">$25/Full Day</div>
              </div>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      
      {/* -------------------------feature cards--------------------- */}
<section
  id="uid976692473851"
  className="zb-section zb-element zb-el-zionSection"
>
  <div className="zb-section__innerWrapper">
    <div className="container">
      <div className='row'>
        <div className="col-lg-4 col-12">
        <div
      id="uid976743781481"
      className="zb-column--4 text-white zb-column zb-element zb-el-zionColumn"
    >
      <h4 id="uid976743781482" className="zb-element zb-el-zionHeading">
        Facilities
      </h4>
      <div id="uid976743781483" className="zb-element zb-el-zionText">
        <ul>
          <li>A clean and safe environment</li>
          <li>Areas for indoor and outdoor play</li>
          <li>A quiet space for rest and naps</li>
          <li>Nutritious meals and snacks.</li>
          <li>Regular but flexible routines goals. </li>
          <li>Air conditioned premises</li>
          <li>Food service</li>
          <li>Separate proper sleeping arrangements</li>
          <li>Pick up and drop facility</li>
        </ul>
      </div>
    </div>
        </div>
        <div className="col-lg-4 col-12">
        <div
      id="uid976744429151"
      className="zb-column--4 text-white zb-column zb-element zb-el-zionColumn"
    >
      <h4 id="uid976744429152" className="zb-element zb-el-zionHeading">
        Features
      </h4>
      <div id="uid976744429153" className="zb-element zb-el-zionText">
        <ul>
          <li>
            {" "}
            Intellectual and physical growth oriented activities every day.{" "}
          </li>
          <li> Planned yearly holidays. </li>
          <li> Planned food and activity schedule.</li>
          <li> Calling time. </li>
          <li> Personal and individual attention.</li>
          <li> Homework Guidance.</li>
          <li> Customized Package.</li>
          <li> Educational Environment.</li>
          <li> Working hours: Mon to Sat, 8am to 8pm. </li>
        </ul>
      </div>
    </div>
        </div>
        <div className="col-lg-4 col-12">
        <div
      id="uid976692500421"
      className="zb-column--4 text-white zb-column zb-element zb-el-zionColumn"
    >
      <h4 id="uid976731746371" className="zb-element zb-el-zionHeading">
        Extra Curricular/ Other Activities
      </h4>
      <div id="uid976731823001" className="zb-element zb-el-zionText">
        <ul>
          {/* <li> Bharatnatyam</li> */}
          <li> Western Dance</li>
          <li> Story Telling</li>
          <li> Gymanstics </li>
          <li> Drawing and Craft </li>
          <li> Phonics &amp; Spell Zone</li>
          {/* <li> Group Tutions</li> */}
          <li> Hand-writing </li>
        </ul>
      </div>
    </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}

export default Nursery