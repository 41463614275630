
import daycare2 from "../Assert/Daycare/day-care.avif";
import daycare3 from "../Assert/Daycare/day4.avif";
import daycare4 from "../Assert/Daycare/daycare7.jpeg";
import daycare5 from "../Assert/Daycare/daycare3.avif";
import daycare6 from "../Assert/Daycare/dayc1.avif";
import daycare7 from "../Assert/gallery/gallery(2).webp";

const gallery = [ 
    // {
    //     img: gallery1,
    // },
    {
        img12:daycare2,
    },
    {
        img12:daycare3,
    },
    {
        img12:daycare4,
    },
    {
        img12: daycare5,
    },
     {
        img12: daycare6,
     },
    {
        img12: daycare7,
    },
]


export default gallery