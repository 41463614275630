import React from "react";
import MetaTags from "react-meta-tags";
import { Tabs } from "antd";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "../Css/Aboutus.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useRef, useState } from "react";
// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { FaUsers } from "react-icons/fa";
import { FaEnvira } from "react-icons/fa";
import { GiCctvCamera } from "react-icons/gi";
import { FaBookOpenReader } from "react-icons/fa6";
import { TbAirConditioning } from "react-icons/tb";
import { MdOutlineSportsKabaddi } from "react-icons/md";
import Breadcrumbs from "./Breadcrumbs";
import aboutus2 from "../Assert/about-page-image.avif";
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Image, Space } from "antd";
import "../Css/Aboutus.css";
import { NavLink } from "react-router-dom";
import PlayGroupData from "../Components/PlayGroupData.jsx";

const AboutUs = () => {
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "Teacher Training & development",
      children: (
        <SwiperSlide>

          <div
            className="tab-pane kinder-pulm  fade active show"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="sec-title  mb-0">
              {/* <h2 className="section-title primary-color">
              <span className="kinder-blue">Welecome To</span>
              Blossom Preschool
            </h2>
            <h4 className="section-subtitle">Information About Blossom</h4> */}
              <p className="section-desc para-vission-mission mb-35">
                Teaching is not just making children achieve their learning
                outcome; it is a process, a series of experiences that lead to the
                great "aha!" moments of life. And only a great teacher can make
                this happen. Like our children our teachers too are lifelong
                learners and engage in self-directed learning. Our teachers get
                into multiple roles of the observer as they listen and watch, the
                supporter as they encourage and accept, the facilitator as they
                inspire and enable, the investor as they demonstrate and surprise
                and the thinker as they adapt and transform.
              </p>
              {/* <a
              className="readon hvr-ripple-out readon-btn kinder-pulm uppercase"
              href="#"
            >
              Read More
            </a> */}
            </div>
          </div>
        </SwiperSlide>
      ),
    },
    {
      key: "2",
      label: "Curiculum & pedagogy",
      children: (
        <SwiperSlide>
          <div
            className="tab-pane fade active show"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="sec-title mb-0">
              {/* <h2 className="section-title primary-color">
              <span className="kinder-blue">Welecome To</span>
              Blossom Preschool
            </h2>
            <h4 className="section-subtitle">Information About Blossom</h4> */}
              <p className="section-desc para-vission-mission mb-35">
                Our curriculum includes strategies and detailed activities that
                range across the content areas of literacy, mathematics, science,
                social science, aesthetics, and socio-emotional development. It
                carefully scaffolds learning to develop the future skills and
                habits of mind in every child. Our children shift from being
                passive receivers to active readers, evaluators, thinkers, and
                innovators as they explore.
              </p>
              {/* <a
              className="readon hvr-ripple-out readon-btn kinder-pulm uppercase"
              href="#"
            >
              Read More
            </a> */}
            </div>
          </div>
        </SwiperSlide>
      ),
    },
    {
      key: "3",
      label: "Outcomes & assessments",
      children: (
        <SwiperSlide>
          <div
            className="tab-pane fade active show"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="sec-title  mb-0">
              {/* <h2 className="section-title primary-color">
              <span className="kinder-blue">Welecome To</span>
              Blossom Preschool
            </h2>
            <h4 className="section-subtitle">Information About Blossom </h4> */}
              <p className="section-desc para-vission-mission mb-35">
                Our assessment focuses attention on the things that our children
                CAN DO, instead of the things they cannot and lays down a clear
                path to make sure that they learn what they are yet to learn. Thus
                the assessment framework is designed to make the learning visible
                by using methods and tools to record, track, and measure and
                communicate the learning competences of each child. The portfolio,
                anecdotal records, observation trackers and the developmental
                profile makes sure that we get a complete picture of the child
                which culminates into the iCan Days and Learner Led Conferences
                where our children themselves demonstrate their own learning.
              </p>
              {/* <a
              className="readon hvr-ripple-out readon-btn kinder-pulm uppercase"
              href="#"
            >
              Read More
            </a> */}
            </div>
          </div>
        </SwiperSlide>
      ),
    },
    {
      key: "4",
      label: "Immersive Learning Spaces",
      children: (
        <SwiperSlide>
          <div
            className="tab-pane fade active show"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className=" sec-title  mb-0">
              {/* <h2 className="section-title primary-color">
              <span className="kinder-blue">Welecome To</span>
              Blossom Preschool
            </h2>
            <h4 className="section-subtitle">Information About Blossom </h4> */}
              <p className="section-desc para-vission-mission mb-35">
                When the children experience fluid spaces that merge their daily
                learning rhythms with the environment and surrounding it ignites
                the possibilities for children’s exploration, imagination,
                creativity, and decision-making. We have designed our spaces with
                the intention to open up opportunities for children to nurture
                their playfulness as they seek, participate, persist, and develop
                their abilities to learn.
              </p>
              {/* <a
              className="readon hvr-ripple-out readon-btn kinder-pulm uppercase"
              href="#"
            >
              Read More
            </a> */}
            </div>
          </div>
        </SwiperSlide>
      ),
    },
    {
      key: "5",
      label: "Tools & Equipment",
      children: (
        <SwiperSlide>
          <div
            className="tab-pane fade active show"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="sec-title  mb-0">
              {/* <h2 className="section-title primary-color">
              <span className="kinder-blue">Welecome To</span>
              Blossom Preschool
            </h2>
            <h4 className="section-subtitle">Information About Blossom </h4> */}
              <p className="section-desc para-vission-mission mb-35">
                We use multiple materials, media and medium which includes open
                resources like paints, paper, pencils, brushes, leaves, pebbles,
                twigs, cloth pieces, to closed resources like toys, equipment,
                board games, audios, videos, apps, augmented reality, virtual
                reality, books etc. that have been curetted and specially created
                to develop specific age appropriate skill set in our children.
              </p>
              {/* <a
              className="readon hvr-ripple-out readon-btn kinder-pulm uppercase"
              href="#"
            >
              Read More
            </a> */}
            </div>
          </div>
        </SwiperSlide>

      ),
    },
  ];
  return (
    <>
      <MetaTags>
        <title>
          Play Group Nursery and Daycare in Borivali | Blossom Preschool
        </title>
        <meta title="Top Salon In Andheri | Just Mirror Salon" />
        <meta
          name="description"
          content="Premier Borivali-based Play Group Nursery and Daycare, Blossom Preschool fosters children's growth in a nurturing environment."
        />
        <meta
          name="keywords"
          content="Blossom Play-group Borivali,Play Group Nursery and Daycare in Borivali,Daycare Center in Borivali,Play Group Nursery in Borivali,Best Nursery in Borivali,Preschool in Borivali,Kids Summer Camp in Borivali West,Play Group Nursery near Borivali"
        />
        <link
          rel="canonical"
          href="https://justmirrorsalon.in/top-salon-andheri"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Play Group Nursery and Daycare in Borivali | Blossom Preschooll"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://justmirrorsalon.in/top-salon-andheri"
        />
        <meta
          property="og:description"
          content="Premier Borivali-based Play Group Nursery and Daycare, Blossom Preschool fosters children's growth in a nurturing environment."
        />
        <meta
          property="og:image"
          content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <Breadcrumbs
        pageName="Play Group Nursery and Daycare in Borivali"
        slugs="About"
      />
      <section>
        <div id="rs-about" className="rs-about align-items-center sec-spacer">
          <div className="container">
            <div className="icon-image">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="about-tab">
                    <div className="sec-title  mb-0">
                      <h2 className="section-title primary-color">
                        <span className="kinder-blue">Welecome To</span>
                        Blossom Preschool
                      </h2>
                      <h4 className="section-subtitle">
                        Information About Blossom{" "}
                      </h4>
                      <h5>
                        With 25 years experience at Shimpoli and Jairaj Nagar
                        Branch
                      </h5>
                      <p className="section-desc mb-35">
                        Since 1999, Blossoms Play Group Nursery and Daycare in
                        Borivali has been a beacon of excellence in Mumbai,
                        providing top-tier education and care for children. Our
                        facility boasts spacious play areas and engaging
                        activities, creating a nurturing environment where every
                        child can thrive.
                      </p>
                      <p className="section-desc mb-35">
                        With a strong commitment to developmentally appropriate
                        learning and brain-based techniques, Blossoms Play Group
                        Nursery and Daycare in Borivali stands out by ensuring a
                        supportive and joyful atmosphere. Choose Blossoms Play
                        Group Nursery and Daycare in Borivali for an innovative
                        and enriching preschool experience where education meets
                        fun and excellence.
                      </p>
                      {/* <a
                        className="readon hvr-ripple-out readon-btn kinder-pulm uppercase"
                        href="#"
                      >
                        Read More
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="about-img">
                    <img
                      src={aboutus2}
                      alt=" Nursery and Daycare in Borivali"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="sec-title text-center mt-lg-5">
                    <h2 className="section-title mb-10 kinder-pulm"></h2>
                    <h4 className="section-subtitle">Working Terms</h4>
                  </div>
                  <div className="about-tab">
                    <Swiper
                      pagination={{
                        type: "progressbar",
                      }}
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <Tabs
                          defaultActiveKey="1"
                          items={items}
                          onChange={onChange}
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* video sec start */}
      <div className="rs-services pt-90 pb-100 bg-grey">
        <div className="container">
          <div className="sec-why text-center">
            {/* <h2 className="section-title mb-10 kinder-pulm">Our Videos</h2> */}
            <h4 className="section-subtitle">
              Our Videos
            </h4>
          </div>
          <div className="row">
            <div className="col-md-6 ">
              <iframe width="100%" height="100%" className="about-us-youtbe-link" src="https://www.youtube.com/embed/Krc2wuDRnug?si=F1HC8qOPrQiidMj0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-md-6">
              <iframe width="100%" height="100%" className="about-us-youtbe-link" src="https://www.youtube.com/embed/9rh-ViJF3Zs?si=iLbUPwzljxaqjRqQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-md-6">
              <iframe width="100%" height="100%" className="about-us-youtbe-link" src="https://www.youtube.com/embed/v9d-MpoHeGI?si=XZzvuvcscpXpRpxJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-md-6">
              <iframe width="100%" height="100%" className="about-us-youtbe-link" src="https://www.youtube.com/embed/3Ze45IpJimU?si=EuRizampu_KP5LYs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
          {/* <div className="viewmore-btn text-center">
            <a className="readon readon-btn hvr-ripple-out uppercase" href="#">
              View All Services
            </a>
          </div> */}
        </div>
      </div>
      {/* video sec end */}
      {/* service start */}
      <div className="rs-services pt-90 pb-100 bg-grey">
        <div className="container">
          <div className="sec-why text-center">
            <h2 className="section-title mb-10 kinder-pulm">Why Choose Us</h2>
            <h4 className="section-subtitle">
              Our Best Services For Your Kids
            </h4>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-50">
              <div className="single-service style-2">
                <div className="icon-style">
                  {/* <i className="flaticon-teacher"> */}
                  <FaUsers className="activities-icon" />
                  {/* </i> */}
                </div>
                <div className="service-text">
                  <h3 className="service-title">Great Teacher</h3>
                  <p className="service-desc">
                    A great teacher possesses an infectious passion for their
                    subject, igniting curiosity and inspiring students to delve
                    deeper into their learning.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-50">
              <div className="single-service style-2">
                <div className="icon-style">
                  {/* <i className="flaticon-bus"> */}
                  <FaBookOpenReader className="activities-icon" />
                  {/* </i> */}
                </div>
                <div className="service-text">
                  <h3 className="service-title">
                    <span className="kinder-blue">Story Telling</span>
                  </h3>
                  <p className="service-desc">
                    Storytelling is the art of weaving words into captivating
                    narratives that transport audiences to different worlds,
                    evoke emotions, and provoke thoughts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-50">
              <div className="single-service style-2">
                <div className="icon-style ">
                  {/* <i className="flaticon-fast"> */}
                  <MdOutlineSportsKabaddi className="activities-icon" />
                  {/* </i> */}
                </div>
                <div className="service-text">
                  <h3 className="service-title">
                    <span className="kinder-orange">All day Activities</span>
                  </h3>
                  <p className="service-desc">
                    We make sure that the kid is actively present in physical
                    activities accordingly
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-50">
              <div className="single-service style-2">
                <div className="icon-style">
                  {/* <i className="flaticon-guitar"> */}
                  <GiCctvCamera className="activities-icon" />
                  {/* </i> */}
                </div>
                <div className="service-text">
                  <h3 className="service-title">
                    <span className="kinder-yellow">CCTV Monitoring</span>
                  </h3>
                  <p className="service-desc">
                    CCTV monitoring in schools provides an extra layer of
                    security and helps ensure the safety of students and staff.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-50">
              <div className="single-service style-2">
                <div className="icon-style">
                  {/* <i className="flaticon-walk"> */}
                  <TbAirConditioning className="activities-icon" />
                  {/* </i> */}
                </div>
                <div className="service-text">
                  <h3 className="service-title">
                    <span className="kinder-pink">Fully A.C. Premises</span>
                  </h3>
                  <p className="service-desc">
                    Fully air-conditioned premises in schools ensure a conducive
                    learning environment for students, fostering comfort and
                    concentration throughout their academic pursuits.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-50">
              <div className="single-service style-2">
                <div className="icon-style">
                  <FaEnvira className="activities-icon" />
                  {/* </i> */}
                </div>
                <div className="service-text">
                  <h3 className="service-title">
                    <span className="kinder-green">
                      Educational Environment
                    </span>
                  </h3>
                  <p className="service-desc">
                    In a nurturing educational environment, students flourish,
                    empowered by knowledge, curiosity, and supportive guidance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="viewmore-btn text-center">
            <a className="readon readon-btn hvr-ripple-out uppercase" href="#">
              View All Services
            </a>
          </div> */}
        </div>
      </div>

      {/* service End */}
      {/* <section className="vision-mission-sec">
      <div id="rs-about" className="rs-about align-items-center sec-spacer">
        <div className="container vision-mission-contain">
          <div className=" ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="about-img">
                  <img src={aboutus3} alt="img02" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="about-tab">
                  <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={onChange}
                  />
                
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section> */}

      <div className="rs-kid-cta primary-bg pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 mb-md-30">
              <div className="cta-text white-color">
                <h2 className="margin-0 white-color">
                  Enroll Your Child In Our School
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="cta-button">
                <NavLink
                  to="/play-group-nursery-near-borivali"
                  className="readon hvr-ripple-out readon-cta white uppercase"
                >
                  Enroll Now
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {/* {serviceData.map((v,i)=>{ */}
        {/* {galleryData.map((v,i)=>{
                return( */}
        {/* {galleryJson.map((v,i)=>(
                    
    )})} */}
        {PlayGroupData.map((v, i) => (
          <SwiperSlide>
            {/* <> */}
            <Image
              width=""
              height="250px"
              alt=" Daycare Nursery and Daycare in Borivali"
              src={v.img}
              preview={{
                toolbarRender: (
                  _,
                  {
                    transform: { scale },
                    actions: {
                      onFlipY,
                      onFlipX,
                      onRotateLeft,
                      onRotateRight,
                      onZoomOut,
                      onZoomIn,
                    },
                  }
                ) => (
                  <Space size={0} className="toolbar-wrapper">
                    {/* <DownloadOutlined onClick={onDownload} /> */}
                    <SwapOutlined rotate={90} onClick={onFlipY} />
                    <SwapOutlined onClick={onFlipX} />
                    <RotateLeftOutlined onClick={onRotateLeft} />
                    <RotateRightOutlined onClick={onRotateRight} />
                    <ZoomOutOutlined
                      disabled={scale === 1}
                      onClick={onZoomOut}
                    />
                    <ZoomInOutlined
                      disabled={scale === 50}
                      onClick={onZoomIn}
                    />
                  </Space>
                ),
              }}
            />

            {/* </> */}
          </SwiperSlide>
        ))}

        {/* ) */}
        {/* })}    */}
      </Swiper>
    </>
  );
};

export default AboutUs;
