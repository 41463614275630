import day1 from "../Assert/Daycare/daycare7.jpeg"
import day2 from "../Assert/Daycare/daycare3.avif"
import day3 from "../Assert/Daycare/daycare2.avif"
import day4 from "../Assert/Daycare/day-care.avif"
import day5 from "../Assert/gallery/dayCare/daycare(1).avif";
import day6 from "../Assert/gallery/dayCare/daycare(2).avif";
import day7 from "../Assert/gallery/dayCare/daycare(3).avif";
import day8 from "../Assert/gallery/dayCare/daycare(4).avif";
import day9 from "../Assert/gallery/dayCare/daycare(5).avif";
import day10 from "../Assert/gallery/dayCare/daycare(6).avif";
import day11 from "../Assert/gallery/dayCare/daycare(7).avif";
import day12 from "../Assert/gallery/dayCare/daycare(8).avif";
import day13 from "../Assert/gallery/dayCare/daycare(9).avif";
import day14 from "../Assert/gallery/dayCare/daycare(10).avif";
import day15 from "../Assert/gallery/dayCare/daycare(11).avif";
import day16 from "../Assert/gallery/dayCare/day(12).jpeg";
import day17 from "../Assert/gallery/dayCare/day(13).jpeg";
import day18 from "../Assert/gallery/dayCare/day(14).jpeg";
import day19 from "../Assert/gallery/dayCare/day(15).jpeg";
import day20 from "../Assert/gallery/dayCare/day(16).jpeg";
import day21 from "../Assert/gallery/dayCare/day(17).jpeg";
import day22 from "../Assert/gallery/dayCare/day(18).jpeg";
import day23 from "../Assert/gallery/dayCare/day(19).jpeg";
import day24 from "../Assert/gallery/dayCare/day(20).jpeg";
import day25 from "../Assert/gallery/dayCare/day(21).jpeg";
import day26 from "../Assert/gallery/dayCare/day(22).jpeg";
import day27 from "../Assert/gallery/dayCare/day(23).jpeg";
import day28 from "../Assert/gallery/dayCare/day(24).jpeg";

import palygroup1 from "../Assert/plygrp (1).avif"
import palygroup2 from "../Assert/plygrp1.avif"
import palygroup4 from "../Assert/bullockcart/WhatsApp-Image-2024-04-27-at-12.44.24.avif"
 import palygroup9 from "../Assert/Daycare/daycare3.avif"
import pg1 from "../Assert/gallery/pg/pg(1).avif";
import pg2 from "../Assert/gallery/pg/pg(2).avif"
import pg3 from "../Assert/gallery/pg/pg(3).avif"
import pg4 from "../Assert/gallery/pg/pg(4).avif"
import pg5 from "../Assert/gallery/pg/pg(5).avif"
import pg6 from "../Assert/gallery/pg/pg(6).avif"
import pg7 from "../Assert/gallery/pg/pg(7).avif"
import pg8 from "../Assert/gallery/pg/pg(8).avif"
import pg9 from "../Assert/gallery/pg/pg(9).avif"

// import palygroup10 from "../Assets/omkar-image-10.jpg"



import nur1 from "../Assert/plygrp1.avif"
import nur2 from "../Assert/Nurcery/nurcery1.avif"
import nur3 from "../Assert/Nurcery/nursery2.avif"
import nur4 from "../Assert/Nurcery/nursery3.avif"
import nur5 from "../Assert/Nurcery/nursery4.avif"
import nur6 from "../Assert/nurr.avif"
import nur7 from "../Assert/gallery/nursey/nursery(1).avif"
import nur8 from "../Assert/gallery/nursey/nursery(2).avif"
import nur9 from "../Assert/gallery/nursey/nursery(3).avif"
import nur10 from "../Assert/gallery/nursey/nursery(4).avif"
import nur11 from "../Assert/gallery/nursey/nursery(5).avif"
import nur12 from "../Assert/gallery/nursey/nursery(6).avif"
import nur13 from "../Assert/gallery/nursey/nursery(7).avif"
import nur14 from "../Assert/gallery/nursey/nursery(8).avif"
import nur15 from "../Assert/gallery/nursey/nursery(9).avif"
import nur16 from "../Assert/gallery/nursey/nursery(10).avif"
import nur17 from "../Assert/gallery/nursey/nursery(11).avif"
import nur18 from "../Assert/gallery/nursey/nursery(12).avif"
import nur19 from "../Assert/gallery/nursey/nursery(13).avif"
import nur20 from "../Assert/gallery/nursey/nursery(14).avif"
import nur21 from "../Assert/gallery/nursey/nursery(15).avif"
import nur22 from "../Assert/gallery/nursey/nursery(16).avif"
import nur23 from "../Assert/Nurcery/Nursery(1).jpeg";
import nur24 from "../Assert/Nurcery/Nursery(2).jpeg";
import nur25 from "../Assert/Nurcery/Nursery(3).jpeg";
import nur26 from "../Assert/Nurcery/Nursery(4).jpeg";
import nur27 from "../Assert/Nurcery/Nursery(5).jpeg";
import nur28 from "../Assert/Nurcery/Nursery(6).jpeg";


// import nur6 from "../Assets/activityslid.jpg"
import summer3 from "../Assert/gallery/gallery(3).webp"
import summer2 from "../Assert/yoga/WhatsApp-Image-2024-04-27-at-11.26.39-_2_.avif"
import summer5 from "../Assert/gymnastic.avif"
import summer6 from "../Assert/yoga/yoga5.avif"
import summer7 from "../Assert/yoga/yoga4.avif"
import summer8 from "../Assert/gallery/gallery(1).webp"
import summer9 from "../Assert/Daycare/daycare3.avif"
import summer10 from "../Assert/NationalPark/npadven2.avif"



const omkarNature = [
// ********************************************************************************************
      {
          id: 1,
          title: "Day care",
          category:"day",
          imageUr1: day1
        },
      {
          id: 2,
          title: "Day care",
          category:"day",
          imageUr1: day2,
          width: 4,
         height: 3,
         
          
          
        },
      // {
      //     id: 3,
      //     title: "Day care",
      //     category:"day",
      //     imageUr1: day3,  
      //     width: 2,
      //     height: 3,         
          
          
      //   },
      // {
      //     id: 4,
      //     title: "Day care",
      //     category:"day",
      //     imageUr1: day4  ,  
      //     width: 4,
      //     height: 1,      
          
          
      //   },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day5  ,  
          width: 4,
          height: 1,      
          
          
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day6  ,  
          width: 4,
          height: 1,      
          
          
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day7  ,  
          width: 4,
          height: 1,      
          
          
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day8  ,  
          width: 4,
          height: 1,      
          
          
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day9,  
          width: 4,
          height: 1,      
          
          
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day10 ,  
          width: 4,
          height: 1,      
          
          
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day11,  
          width: 4,
          height: 1,      
          
          
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day12,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day13,  
          width: 4,
          height: 1,      
          
          
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day14,  
          width: 4,
          height: 1,      
          
          
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day15,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day16,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day17,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day18,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day19,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day20,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day21,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day22,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day23,  
          width: 4,
          height: 1,      
        },
        {
          id: 4,
          title: "Day care",
          category:"day",
          imageUr1: day24,  
          width: 4,
          height: 1,      
        },
        // ******************************************playgroup************************************************************

       

        {
          id:1,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: palygroup1,
          width: 4,
    height: 3,
        },
    //     {
    //       id:2,
    //       title: "PlayGroup",
    //       category:"PlayGroup",
    //       imageUr1: palygroup2,
    //       width: 1,
    // height: 1,
    //     },

        {
          id:3,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: palygroup4,
          width: 3,
          height: 4,
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: palygroup9,
          width: 1,
          height: 2,
          
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: pg1,
          width: 1,
          height: 2,
          
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: pg2,
          width: 1,
          height: 2,
          
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: pg3,
          width: 1,
          height: 2,
          
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: pg4,
          width: 1,
          height: 2,
          
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: pg5,
          width: 1,
          height: 2,
          
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: pg6,
          width: 1,
          height: 2,
          
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: pg7,
          width: 1,
          height: 2,
          
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: pg8,
          width: 1,
          height: 2,
          
        },
        {
          id:4,
          title: "PlayGroup",
          category:"PlayGroup",
          imageUr1: pg9,
          width: 1,
          height: 2,
          
        },
        // **************************************************Nursery*******************************************************
        {
          id:1,
          title: "Nursery",
          category:"Nursery",
          imageUr1: nur1,
          width: 3,
          height: 4,
          
        },
        {
          id:2,
          title: "Nursery",
          category:"Nursery",
          imageUr1: nur2,
          width: 4,
          height: 3,
        
        },
        // {
        //   id:3,
        //   title: "Nursery",
        //   category:"Nursery",
        //   imageUr1: nur3,
        //   width: 2,
        //   height: 2,
        // },
        // {
        //   id:4,
        //   title: "Nursery",
        //   category:"Nursery",
        //   imageUr1:nur4,
        //   width: 4,
        //   height: 4,
        // },
        {
          id:5,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur5,
          width: 2,
          height: 4,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur6,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur7,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur8,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur9,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur10,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur11,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur12,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur13,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur14,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur15,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur16,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur17,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur18,
          width: 1,
          height: 3,
        },
        // {
        //   id:6,
        //   title: "Nursery",
        //   category:"Nursery",
        //   imageUr1:nur19,
        //   width: 1,
        //   height: 3,
        // },
        // {
        //   id:6,
        //   title: "Nursery",
        //   category:"Nursery",
        //   imageUr1:nur20,
        //   width: 1,
        //   height: 3,
        // },
        // {
        //   id:6,
        //   title: "Nursery",
        //   category:"Nursery",
        //   imageUr1:nur21,
        //   width: 1,
        //   height: 3,
        // },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur22,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur23,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur24,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur25,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur26,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur27,
          width: 1,
          height: 3,
        },
        {
          id:6,
          title: "Nursery",
          category:"Nursery",
          imageUr1:nur28,
          width: 1,
          height: 3,
        },
        // ***************************************************Summer*************************************************************************
        {
          id:1,
          title: "Summer Camp",
          category:"summer",
          imageUr1: summer3,
          width: 1,
          height: 1,
        },
  
        {
          id:2,
          title: "Summer Camp",
          category:"summer6",
          imageUr1:summer2,
          width: 3,
          height: 4,
        },
  
        {
          id:3,
          title: "Summer Camp",
          category:"summer",
          imageUr1: summer5  ,
          width: 3,
          height: 2,
          
        },
        {
          id:4,
          title: "Summer Camp",
          category:"summer",
          imageUr1: summer6,
          width: 1,
          height: 4,
          
        },
        {
          id:5,
          title: "Summer Camp",
          category:"summer",
          imageUr1: summer7,
          width: 1,
          height: 4,
          
        },
        {
          id:6,
          title: "Summer Camp",
          category:"summer",
          imageUr1: summer8,
          width: 1,
          height: 4,
          
        },
        {
          id:7,
          title: "Summer Camp",
          category:"summer",
          imageUr1: summer9,
          width: 1,
          height: 4,
          
        },
        {
          id:8,
          title: "Summer Camp",
          category:"summer",
          imageUr1: summer10,
          width: 1,
          height: 4,
          
        },
    
  
]
export default omkarNature

