
////////////// Door Images //////////////////

import religious1 from "../Assert/NationalPark/WhatsApp Image 2024-04-27 at 19 (1).avif";
import religious2 from "../Assert/NationalPark/WhatsApp Image 2024-04-27 at 19 (2).avif";
import religious3 from "../Assert/NationalPark/npadventure3.avif";
import religious4 from "../Assert/NationalPark/WhatsApp Image 2024-04-27 at 19.35.26.jpeg";
import religious5 from "../Assert/NationalPark/npAdven-1.avif";
// ------------------yoga--------------------
import yoga1 from "../Assert/yoga/WhatsApp Image 2024-04-26 at 14.avif";
import yoga2 from "../Assert/yoga/WhatsApp-Image-2024-04-27-at-11.26.39-_2_.avif";
import yoga3 from "../Assert/yoga/WhatsApp-Image-2024-04-27-at-11.26.39.avif";
import yoga4 from "../Assert/yoga/yoga1.avif";
import yoga5 from "../Assert/yoga/yoga2.avif";
import yoga6 from "../Assert/yoga/yoga3.avif";
import yoga7 from "../Assert/yoga/yoga4.avif";
import yoga8 from "../Assert/yoga/yoga5.avif";
// ------------------Tie Dye--------------------
import tie1 from "../Assert/tie&dye/WhatsApp Image 2024-04-27 at 11 (1).avif";
import tie2 from "../Assert/tie&dye/WhatsApp Image 2024-04-27 at 11 (2).avif";
import tie3 from "../Assert/tie&dye/WhatsApp Image 2024-04-27 at 11 (3).avif";
import tie4 from "../Assert/tie&dye/tie1.avif";
import tie5 from "../Assert/tie&dye/tie2.avif";
import tie6 from "../Assert/tie&dye/tie3.avif";
import tie7 from "../Assert/tie&dye/tie4.avif";
import tie8 from "../Assert/tie&dye/tie5.avif";
import tie9 from "../Assert/tie&dye/tie6.avif";
import tie10 from "../Assert/tie&dye/tie7.avif";
import tie11 from "../Assert/tie&dye/tie8.avif";

// ------------------Story--------------------
import story1 from "../Assert/storytelling/WhatsApp Image 2024-04-27 at 11 (4).avif";
import story2 from "../Assert/storytelling/WhatsApp Image 2024-04-27 at 11 (5).avif";
import story3 from "../Assert/storytelling/WhatsApp Image 2024-04-27 at 11 (6).avif";
import story4 from "../Assert/storytelling/story1.avif";
import story5 from "../Assert/storytelling/story2.avif";
import story6 from "../Assert/storytelling/story3.avif";

// ------------------Stage Show--------------------
import show1 from "../Assert/stage-show/WhatsApp Image 2024-04-27 at 11 (10).avif";
import show2 from "../Assert/stage-show/WhatsApp Image 2024-04-27 at 11 (7).avif";
import show3 from "../Assert/stage-show/WhatsApp Image 2024-04-27 at 11 (9).avif";
import show4 from "../Assert/stage-show/story1.avif";
import show5 from "../Assert/stage-show/story2.avif";
import show6 from "../Assert/stage-show/story3.avif";
// ------------------Stone Painting--------------------
import stone1 from "../Assert/stone-painting/WhatsApp Image 2024-04-26 at 14 (1).avif";
import stone2 from "../Assert/stone-painting/WhatsApp Image 2024-04-26 at 14.avif";
// ------------------pyjama party--------------------
import party1 from "../Assert/pyjama-party/WhatsApp Image 2024-04-27 at 12 (2).avif";
import party2 from "../Assert/pyjama-party/WhatsApp Image 2024-04-27 at 12 (3).avif";
import party3 from "../Assert/pyjama-party/WhatsApp Image 2024-04-27 at 12.avif";
import party4 from "../Assert/pyjama-party/pajama1.avif";
import party5 from "../Assert/pyjama-party/pajama2.avif";
import party6 from "../Assert/pyjama-party/pajama3.avif";
import party7 from "../Assert/pyjama-party/pajama4.avif";

// ------------------pottery -------------------
import pot1 from "../Assert/pottery/WhatsApp Image 2024-04-25 at 19.28.07.jpeg";
import pot2 from "../Assert/pottery/WhatsApp Image 2024-04-25 at 19.29.17.jpeg";
import pot3 from "../Assert/pottery/WhatsApp Image 2024-04-25 at 19.29.46.jpeg";
import pot4 from "../Assert/pottery/pot1.avif";
import pot5 from "../Assert/pottery/pot2.avif";
import pot6 from "../Assert/pottery/pot3.avif";
import pot7 from "../Assert/pottery/pot4.avif";
import pot8 from "../Assert/pottery/pot5.avif";
// import pot9 from "../Assert/pottery/pot6.avif";
import pot10 from "../Assert/pottery/pot7.avif";
import pot11 from "../Assert/pottery/pot8.avif";
import pot12 from "../Assert/pottery/pot9.avif";
// ------------------bull -------------------
import bull1 from "../Assert/bullockcart/WhatsApp-Image-2024-04-27-at-12.44.22.avif";
import bill2 from "../Assert/bullockcart/WhatsApp-Image-2024-04-27-at-12.44.24.avif";
// import bull3 from "../Assert/bullockcart/WhatsApp Image 2024-04-27 at 12.44.24.jpeg";


const GalleryData = [
  {
    id: 1,
    Category:"Yoga & Exercise",
    CategoryName:"Yoga & Exercise",
    CImage:yoga1,
    
  },
  {
    id: 1,
    Category:"Yoga & Exercise",
    CategoryName:"Yoga & Exercise",
    CImage:yoga2,
  },
  {
    id: 1,
    Category:"Yoga & Exercise",
    CategoryName:"Yoga & Exercise",
    CImage:yoga4,
  },
  {
    id: 1,
    Category:"Yoga & Exercise",
    CategoryName:"Yoga & Exercise",
    CImage:yoga5,
  },
  {
    id: 1,
    Category:"Yoga & Exercise",
    CategoryName:"Yoga & Exercise",
    CImage:yoga6,
  },
  {
    id: 1,
    Category:"Yoga & Exercise",
    CategoryName:"Yoga & Exercise",
    CImage:yoga7,
  },
 
  {
    id: 1,
    Category:"Yoga & Exercise",
    CategoryName:"Yoga & Exercise",
    CImage:yoga3,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie1,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie2,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie3,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie4,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie5,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie6,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie7,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie8,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie9,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie10,
  },
  {
    id: 1,
    Category:"Tie & Dye",
    CategoryName:"Tie & Dye",
    CImage:tie11,
  },
 
  {
    id: 1,
    Category:"Story Telling",
    CategoryName:"Story Telling",
    CImage:story1,
  },
 
  {
    id: 1,
    Category:"Story Telling",
    CategoryName:"Story Telling",
    CImage:story2,
  },
 
  {
    id: 1,
    Category:"Story Telling",
    CategoryName:"Story Telling",
    CImage:story3,
  },
  {
    id: 1,
    Category:"Story Telling",
    CategoryName:"Story Telling",
    CImage:story4,
  },
  {
    id: 1,
    Category:"Story Telling",
    CategoryName:"Story Telling",
    CImage:story5,
  },
  {
    id: 1,
    Category:"Story Telling",
    CategoryName:"Story Telling",
    CImage:story6,
  },
 
  {
    id: 1,
    Category:"Stone Painting",
    CategoryName:"Stone Painting",
    CImage:stone1,
  },
 
  {
    id: 1,
    Category:"Stone Painting",
    CategoryName:"Stone Painting",
    CImage:stone2,
  },
 
  
 
  {
    id: 1,
    Category:"Stage Show",
    CategoryName:"Stage Show",
    CImage:show1,
  },
 
  {
    id: 1,
    Category:"Stage Show",
    CategoryName:"Stage Show",
    CImage:show2,
  },
 
  {
    id: 1,
    Category:"Stage Show",
    CategoryName:"Stage Show",
    CImage:show3,
  },
  {
    id: 1,
    Category:"Stage Show",
    CategoryName:"Stage Show",
    CImage:show4,
  },
  {
    id: 1,
    Category:"Stage Show",
    CategoryName:"Stage Show",
    CImage:show5,
  },
  {
    id: 1,
    Category:"Stage Show",
    CategoryName:"Stage Show",
    CImage:show6,
  },
  
  {
    id: 1,
    Category:"Pyjama Party",
    CategoryName:"Pyjama Party",
    CImage:party1,
  },
  {
    id: 1,
    Category:"Pyjama Party",
    CategoryName:"Pyjama Party",
    CImage:party2,
  },
  {
    id: 1,
    Category:"Pyjama Party",
    CategoryName:"Pyjama Party",
    CImage:party3,
  },
  {
    id: 1,
    Category:"Pyjama Party",
    CategoryName:"Pyjama Party",
    CImage:party4,
  },
  {
    id: 1,
    Category:"Pyjama Party",
    CategoryName:"Pyjama Party",
    CImage:party5,
  },
  {
    id: 1,
    Category:"Pyjama Party",
    CategoryName:"Pyjama Party",
    CImage:party6,
  },
  {
    id: 1,
    Category:"Pyjama Party",
    CategoryName:"Pyjama Party",
    CImage:party7,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot1,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot2,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot3,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot4,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot5,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot6,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot7,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot8,
  },
  // {
  //   id: 1,
  //   Category:"Pottery",
  //   // Category/Name:"Pottery",
  //   CImage:pot9,
  // },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot10,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot11,
  },
  {
    id: 1,
    Category:"Pottery",
    CategoryName:"Pottery",
    CImage:pot12,
  },
 
 {
    id: 1,
    Category:"Bullock Cart Ride",
    CategoryName:"Bullock Cart Ride",
    CImage:bull1,
  },
 {
    id: 1,
    Category:"Bullock Cart Ride",
    CategoryName:"Bullock Cart Ride",
    CImage:bill2,
  },
//  {
//     id: 1,
//     Category:"Bullock Cart Ride",
//     CategoryName:"Bullock Cart Ride",
//     CImage:bull3,
//   },
  // {
  //   id: 1,
  //   Category:"Scavenger Hunt at National Park",
  //   CategoryName:"Scavenger Hunt at National Park",
  //   CImage:religious1,
  // },
  // {
  //   id: 1,
  //   Category:"Scavenger Hunt at National Park",
  //   CategoryName:"Scavenger Hunt at National Park",
  //   CImage:religious1,
  // },
  {
    id: 1,
    Category:"Scavenger Hunt at National Park",
    CategoryName:"Scavenger Hunt at National Park",
    CImage:religious1,
  },
  {
    id: 1,
    Category:"Scavenger Hunt at National Park",
    CategoryName:"Scavenger Hunt at National Park",
    CImage:religious2,
  },
  {
    id: 1,
    Category:"Scavenger Hunt at National Park",
    CategoryName:"Scavenger Hunt at National Park",
    CImage:religious3,
  },
  {
    id: 1,
    Category:"Scavenger Hunt at National Park",
    CategoryName:"Scavenger Hunt at National Park",
    CImage:religious4,
  },
  {
    id: 1,
    Category:"Scavenger Hunt at National Park",
    CategoryName:"Scavenger Hunt at National Park",
    CImage:religious5,
  },
  
 

  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 

  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 
  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 
  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 

  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 
  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 
  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 
  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 
  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 

  // {
  //   id: 1,
  //   Category:"Summer Camps",
  //   CategoryName:"Summer Camps",
  //   CImage:religious1,
  // },
 

  


];

export default GalleryData;

