import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header.jsx";
import Home from "./Pages/Home.jsx";
import Footer from "./Components/Footer.jsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./Pages/AboutUs.jsx";
import DayCare from "./Pages/DayCare.jsx";
import Playgroup from "./Pages/Playgroup.jsx";
import SummerCamps from "./Pages/SummerCamps.jsx";
import Nursery from "./Pages/Nursery.jsx";
import Gallery from "./Pages/Gallery.jsx";
import Contact from "./Pages/Contact.jsx";
import { ConfigProvider } from "antd";
import Error from "./Pages/Error.jsx";
function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/play-group-nursery-daycare-borivali"
            element={
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      inkBarColor: "#fe6500",
                      itemSelectedColor: "#fe6500",
                    },
                  },
                }}
              >
                <AboutUs />{" "}
              </ConfigProvider>
            }
          />
          <Route path="/daycare-center-borivali" element={<DayCare />} />
          <Route path="/play-group-nursery-borivali" element={<Playgroup />} />
          <Route path="/kids-summer-camp-borivali-west" element={<SummerCamps />} />
          <Route path="/best-nursery-borivali" element={<Nursery />} />
          <Route path="/preschool-borivali" element={<Gallery />} />
          <Route path="/play-group-nursery-near-borivali" element={<Contact />} />
          <Route path="*" element={<Error/>}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
