import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import MetaTags from "react-meta-tags";
import { Button, Input, Form } from "antd";
import $ from "jquery";

const Contact = () => {
  const submitForm = (values) => {
    console.log("values", values);

    var body =
      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#dc9d7e">Blossom Dar Care Center</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#dc9d7e"><strong>Name:</strong></td><td style="text-align:left">' +
      values.name +
      '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Email:</strong></td><td style="text-align:left">' +
      values.email +
      '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Subject:</strong></td><td style="text-align:left">' +
      values.subject +
      '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Message:</strong></td><td style="text-align:left">' +
      values.message +
      '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Mobile Number:</strong></td><td style="text-align:left">' +
      values.mobileNo +
      '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Blossom Dar Care Center</p></div></body></html>';

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "blossoms4predcc@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "blossom",
        accountLeadSource: "https://blossomspreschool.co.in/",
        accountLeadName: values.name,
        accountLeadEmail: values.email,
        accountLeadPhone: values.contact,
      },

      function (dataa, status) {
        console.log("data :" + dataa);
        console.log("name:" + values);
      }
    );

    alert("Your Form has Submitted Our team will contact with You  soon.");
    values.preventDefault();
    return false;
  };
  return (
    <div>
      <MetaTags>
        <title>Play Group Nursery near Borivali | Blossom Preschool</title>
        <meta title="Play Group Nursery near Borivali | Blossom Preschool" />
        <meta
          name="description"
          content="Explore a vibrant, nurturing environment at Blossom Preschool, a premier Play Group Nursery near Borivali fostering holistic child development."
        />
        <meta
          name="keywords"
          content="Blossom Play-group Borivali,Play Group Nursery and Daycare in Borivali,Daycare Center in Borivali,Play Group Nursery in Borivali,Best Nursery in Borivali,Preschool in Borivali,Kids Summer Camp in Borivali West,Play Group Nursery near Borivali"
        />
        <link
          rel="canonical"
          href="https://justmirrorsalon.in/top-salon-andheri"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Play Group Nursery near Borivali | Blossom Preschool"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://justmirrorsalon.in/top-salon-andheri"
        />
        <meta
          property="og:description"
          content="Explore a vibrant, nurturing environment at Blossom Preschool, a premier Play Group Nursery near Borivali fostering holistic child development."
        />
        <meta
          property="og:image"
          content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <Breadcrumbs
        pageName="Play Group Nursery near Borivali"
        slugs="Contact Us"
      />

      <div className="contact-page-section sec-spacer">
        <div className="container">
          <div className="hidden-content">       
          Welcome to Blossom, your premier destination for Play Group Nursery near Borivali. Located in the heart of the vibrant community, Blossom offers a nurturing environment for your child's early years. Whether you're a parent seeking quality childcare or a guardian interested in our comprehensive programs, we're here to support your family's needs. Feel free to reach out to us with any inquiries, or enrollment questions, or to schedule a visit. Our dedicated team is committed to providing a safe, engaging, and educational experience for every child. Contact Blossom today and discover why we're the top choice for families in Borivali seeking excellence in early childhood education.  

          </div>
          <div className="row contact-address-section pb-100">
            <div className="col-md-4 mb-sm-30">
              <div className="contact-info contact-address text-center kinder-pulm-bg">
                <div className="contact-icon kinder-pulm">
                  <i className="fa fa-map-marker" />
                </div>
                <h5>Branch I</h5>
                <p>
                  1 to 4, Kora Kendra New Bldg., Shimpoli Road, Borivali West,
                  Mumbai - 400 092
                  {/* <a href="tel:+3453-909-6565">+88025826</a>{" "}
            <a href="mailto:infoname@gmail.com">infoname@gmail.com</a> //{" "}
            <a href="www.yourname.com">www.yourname.com</a> */}
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-sm-30">
              <div className="contact-info contact-phone text-center kinder-orange-bg">
                <div className="contact-icon kinder-orange">
                  <i className="fa fa-map-marker" />
                </div>
                <h5>Branch II</h5>
                <p>
                  1 to 6 Hemlata Apt., Jairaj Nagar, Borivali West, Mumbai - 400
                  091
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-info contact-email text-center kinder-blue-bg">
                <div className="contact-icon kinder-blue">
                  <i className="fa fa-volume-control-phone" />
                </div>
                <h5>Contact</h5>
                <p>Email: blossoms4predcc@gmail.com</p>
                <p>Contact: 9321317161/9324675168</p>
                <p>Working Hrs: Mon to Sat 8 am to 8 pm</p>
              </div>
            </div>
          </div>
          <div className="row onepage-style">
            <div className="col-lg-6 mb-md-30 pr-25">
              <div className="contact-comment-section">
                <div id="form-messages" />
                <Form
                  className="php-email-form"
                  layout="vertical"
                  onFinish={submitForm}
                >
                  <div className="row">
                    <div className="form-group col-md-6">
                      <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter a name"
                          className="formInput custName custNameValidation"
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group col-md-6">
                      <Form.Item
                        name="subject"
                        label="Subject"
                        rules={[
                          {
                            required: true,
                            message: "Please enter subject!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter subject"
                          className="formInput custPhone custPhoneValidation"
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group col-lg-6">
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter email!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Mail"
                          className="formInput custEmail custEmailValidation"
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group col-lg-6">
                      <Form.Item
                        name="mobileNo"
                        label="Contact"
                        rules={[
                          {
                            required: true,
                            message: "Please enter mobile:no!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Contact No."
                          className="formInput custEmail custEmailValidation"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="form-group">
                    <Form.Item
                      name="message"
                      label="Message"
                      rules={[
                        {
                          required: true,
                          message: "Please Your message!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        className="formInput custMessage"
                        placeholder="Enter message"
                      />
                    </Form.Item>
                  </div>
                  <div className="text-center cutomSubmit">
                    <Form.Item>
                      <Button className="btn bg-dark text-white" htmlType="submit">Send Message</Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-lg-6 pl-25">
              <div className="g-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.5138137231424!2d72.83898007498108!3d19.216426282017295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b73b475d6835%3A0xf2d1dbbfd6a7ca04!2sBlossoms%20Preschool%20%26%20Daycare%20-%20Playgroup%20%7C%20Nursery%20%7C%20Daycare%20Centre%20%7C%20Preschool%20in%20Borivali!5e0!3m2!1sen!2sin!4v1716300735816!5m2!1sen!2sin"
                  width={600}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
                {/* <iframe src="https://maps.google.com/maps?q=Filbert%20St%20%26%20Stockton%20Street%2C%20San%20Francisco%2C%20CA%2094133%2C%20USA&t=&z=13&ie=UTF8&iwloc=&output=embed" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
