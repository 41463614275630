
import nurcery2 from "../Assert/Nurcery/nurcery1.avif"
import nurcery3 from "../Assert/Nurcery/Maratha-dance-group.avif"
import nurcery4 from "../Assert/Nurcery/nursery3.avif"

const NurceryData = [ 
    // {
    //     img: gallery1,
    // },
    {
        img13:nurcery2
    },
    {
        img13:nurcery3
    },
    {
    
        img13:nurcery4
    },
   
]


export default NurceryData