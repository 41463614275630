import nurcery5 from "../Assert/Nurcery/nursery4.avif"
import nurcery6 from "../Assert/Nurcery/Comedy dance.avif"
import nurcery7 from "../Assert/Nurcery/Ganpati-dance-pg.avif"
import nurcery8 from "../Assert/Nurcery/nurdance.avif"
import nurcery9 from "../Assert/Nurcery/Day-care-Journey-to-Blossom.avif"


const PlayGroupData = [ 
    {
        img: nurcery8,
    },
    {
        img: nurcery5,
    },
    {
        img: nurcery6,
    },
    {
        img: nurcery7,
    },
    {
        img: nurcery9,
    },

]


export default PlayGroupData