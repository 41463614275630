import React, { useRef, useState } from 'react';
import Breadcrumbs from './Breadcrumbs'
import MetaTags from 'react-meta-tags';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import  Gallery  from "../Assert/Gallery";


const DayCare = () => {
  return (
    <>
    <MetaTags>
      <title>Day care Center in Borivali | Blossom Preschool</title>
        <meta title="Day care Center in Borivali | Blossom Preschool" />
        <meta
          name="description"
          content="Blossom Preschool offers a trusted Day care Center in Borivali, providing nurturing care and early childhood education in a safe environment."
        />
        <meta
          name="keywords"
          content="Blossom Play-group Borivali,Play Group Nursery and Daycare in Borivali,Day care Center in Borivali,Play Group Nursery in Borivali,Best Nursery in Borivali,Preschool in Borivali,Kids Summer Camp in Borivali West,Play Group Nursery near Borivali"
        />
        <link rel="canonical" href="https://justmirrorsalon.in/top-salon-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Day care Center in Borivali | Blossom Preschool" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://justmirrorsalon.in/top-salon-andheri"/>
        <meta property="og:description" content="Blossom Preschool offers a trusted Day care Center in Borivali, providing nurturing care and early childhood education in a safe environment." />
        <meta property="og:image" content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
         <Breadcrumbs pageName="Day care Center in Borivali" slugs="Daycare-center-borivali" />
         <div className="event-single-section sec-spacer">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-12 mb-md-30">
        {/* <div className="event-single-img"> */}
        <Swiper
        spaceBetween={30}
        centeredSlides={true}
        
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {Gallery.map((v,i)=>(
          <SwiperSlide>
            <div className="swiperImg">
            <img src={v.img12} alt='Day care Center' />
            </div>
          
          </SwiperSlide>
        ))}
      </Swiper>
        {/* </div> */}
      </div>
      <div className="col-lg-6 col-12">
        <div className="event-single-description">
          <h2 className="event-title primary-color">Day care Benefits:</h2>
          <p className="event-desc">
          Our Day care Center in Borivali stands out as a beacon of excellence in early childhood education. With a dedicated team of experienced educators, we prioritize the holistic development of every child entrusted to our care. Through a meticulously designed curriculum and a nurturing environment, we foster growth and learning in infants, toddlers, preschoolers, and kindergarteners


          </p>
          <p className="event-desc">
          At our Day care Center in Borivali, we emphasize individualized schedules and experiences tailored to meet each child's unique needs. By providing a blend of freedom and structure, we encourage exploration and growth. Our focus on safety, inclusivity, and celebrating the talents of every child ensures a supportive and enriching atmosphere


          </p>
          <p className="event-desc mb-0">
          Committed to laying a strong foundation for future success, our Day care Center in Borivali offers an unparalleled early childhood education experience that supports the comprehensive development of each child in our care.
          </p>
          
        </div>
        {/* <div className="g-map">
          <iframe src="https://maps.google.com/maps?q=Filbert%20St%20%26%20Stockton%20Street%2C%20San%20Francisco%2C%20CA%2094133%2C%20USA&t=&z=13&ie=UTF8&iwloc=&output=embed" />
        </div> */}
      </div>
      {/* <div className="col-lg-4">
        <div className="event-single-details">
          <h2 className="info-title white-color">Event Details</h2>
          <ul className="event-info mb-30">
            <li>
              <span className="event-topic">Start Date</span>
              <span className="event-time">12 June, 2019</span>
            </li>
            <li>
              <span className="event-topic">Start Time</span>
              <span className="event-time">12:40 pm</span>
            </li>
            <li>
              <span className="event-topic">End Date</span>
              <span className="event-time">10 August, 2019</span>
            </li>
            <li>
              <span className="event-topic">End Time</span>
              <span className="event-time">03:00 pm</span>
            </li>
            <li>
              <span className="event-topic">Location</span>
              <span className="event-time">Gulshan Dhaka</span>
            </li>
          </ul>
          <div className="event-btn text-center">
            <a className="readon hvr-ripple-out uppercase" href="#">
              Book Now
            </a>
          </div>
        </div>
      </div> */}
    </div>
  
  </div>
</div>
{/* -------------------------feature cards--------------------- */}
<section
  id="uid976692473851"
  className="zb-section zb-element zb-el-zionSection"
>
  <div className="zb-section__innerWrapper">
    <div className="container">
      <div className='row'>
        <div className="col-lg-4 col-12">
        <div
      id="uid976743781481"
      className="zb-column--4 text-white zb-column zb-element zb-el-zionColumn"
    >
      <h4 id="uid976743781482" className="zb-element zb-el-zionHeading">
        Facilities
      </h4>
      <div id="uid976743781483" className="zb-element zb-el-zionText">
        <ul>
          <li>A clean and safe environment</li>
          <li>Areas for indoor and outdoor play</li>
          <li>A quiet space for rest and naps</li>
          <li>Nutritious meals and snacks.</li>
          <li>Regular but flexible routines goals. </li>
          <li>Air conditioned premises</li>
          <li>Food service</li>
          <li>Separate proper sleeping arrangements <br/> (Day Care Center) </li>
          <li>Pick up and drop facility</li>
        </ul>
      </div>
    </div>
        </div>
        <div className="col-lg-4 col-12">
        <div
      id="uid976744429151"
      className="zb-column--4 text-white zb-column zb-element zb-el-zionColumn"
    >
      <h4 id="uid976744429152" className="zb-element zb-el-zionHeading">
        Features
      </h4>
      <div id="uid976744429153" className="zb-element zb-el-zionText">
        <ul>
          <li>
            {" "}
            Intellectual and physical growth oriented activities every day.{" "}
          </li>
          <li> Planned yearly holidays. </li>
          <li> Planned food and activity schedule.</li>
          <li> Calling time.(Day Care Center) </li>
          <li> Personal and individual attention.</li>
          <li> Homework Guidance.</li>
          <li> Customized Package.(Day Care Center) </li>
          <li> Educational Environment.</li>
          <li> Working hours: Mon to Sat, 8am to 8pm. </li>
        </ul>
      </div>
    </div>
        </div>
        <div className="col-lg-4 col-12">
        <div
      id="uid976692500421"
      className="zb-column--4 text-white zb-column zb-element zb-el-zionColumn"
    >
      <h4 id="uid976731746371" className="zb-element zb-el-zionHeading">
        Extra Curricular/ Other Activities
      </h4>
      <div id="uid976731823001" className="zb-element zb-el-zionText">
        <ul>
          {/* <li> Bharatnatyam</li> */}
          <li> Western Dance</li>
          <li> Story Telling</li>
          <li> Gymanstics </li>
          <li> Drawing and Craft </li>
          <li> Phonics &amp; Spell Zone</li>
          {/* <li> Group Tutions</li> */}
          <li> Hand-writing </li>
        </ul>
      </div>
    </div>
        </div>
      </div>
    </div>
    
   
    
    
  </div>
</section>

    </>
  )
}

export default DayCare