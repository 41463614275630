import React, { useRef, useState } from "react";
import "../Css/Home.css";
import {Link} from "react-router-dom"
import gym from "../Assert/gymnastic.avif"
import dance from "../Assert/westerndance.webp"
import hand from "../Assert/Daycare/dayc1.avif"
import blow1 from "../Assert/blow-banner-1.avif"
import blow2 from "../Assert/blow-banner-2_1.avif"
import phonics from "../Assert/phonics.webp"
import art from "../Assert/artRaft.webp"
import MetaTags from 'react-meta-tags';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import './styles.css';

import { FaEnvira } from "react-icons/fa";

import ball3 from "../Assert/ball3.png";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import homeAbout from "../Assert/icons/WhatsApp_Image_2024-05-22_at_11.21.40_AM-removebg-preview.png";

// import galleryData from "../Asserts/GalleryData";
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Image, Space } from "antd";

import PlayGroupData from "../Components/PlayGroupData.jsx";
import Journey from "./Journey.jsx";


const Home = () => {
  return (
    <>
    <MetaTags>
      <title>Blossom Play-group Borivali| Blossom Preschool</title>
        <meta title="Blossom Play-group Borivali | Blossom Preschool" />
        <meta
          name="description"
          content="Blossom Play-group Borivali offers nurturing preschool education in Borivali, fostering growth and development in a safe, engaging environment."
        />
        <meta
          name="keywords"
          content="Blossom Play-group Borivali,Play Group Nursery and Daycare in Borivali,Daycare Center in Borivali,Play Group Nursery in Borivali,Best Nursery in Borivali,Preschool in Borivali,Kids Summer Camp in Borivali West,Play Group Nursery near Borivali"
        />
        <link rel="canonical" href="https://justmirrorsalon.in/top-salon-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Blossom Play-group Borivali | Blossom Preschool" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://justmirrorsalon.in/top-salon-andheri"/>
        <meta property="og:description" content="Blossom Play-group Borivali offers nurturing preschool education in Borivali, fostering growth and development in a safe, engaging environment." />
        <meta property="og:image" content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <Swiper
        spaceBetween={10}
        centeredSlides={true}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="owl-item cloned active" style={{ marginRight: 0 }}>
            <div className="item active banner-one-card">
              <img
                src={blow1}
                alt=" Borivali"
              />
              <div className="slide-content banner-one-content">
                <div className="display-table">
                  <div className="display-table-cell">
                    <div className="container text-center">
                      <div className="sliter-title-text">
                        <h1
                          className="slider-title kinder-pulm white-layer banner-text"
                          data-animation-in="fadeInLeft"
                          data-animation-out="animate-out"
                        >
                          {" "}
                          <span className="kinder-orange">Blossom Play-group Borivali</span>
                        </h1>{" "}
                        <br />
                        <h2
                          className="slider-title kinder-pulm white-layer banner-text"
                          data-animation-in="fadeInLeft"
                          data-animation-out="animate-out"
                        >
                          <span className="kinder-blue">For Your</span> Kids
                        </h2>
                      </div>
                      <div className="slider-button banner-button ">
                        <Link
                          to="/play-group-nursery-near-borivali"
                          className="readon hvr-ripple-out readon-cta uppercase"
                          data-animation-in="lightSpeedIn"
                          data-animation-out="animate-out"
                        >
                          Contact us Now
                        </Link>
                        {/* <a
                          href="#"
                          className="readon hvr-ripple-out readon-cta secondary uppercase"
                          data-animation-in="lightSpeedIn"
                          data-animation-out="animate-out"
                        >
                          Enquire Now
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="owl-item cloned active" style={{ marginRight: 0 }}>
            <div className="item active banner-one-card">
              <img
                src={blow2}
                alt="Blossom Preschool "
              />
              <div className="slide-content banner-one-content">
                <div className="display-table">
                  <div className="display-table-cell">
                    <div className="container text-center">
                      <div className="sliter-title-text">
                        <h2
                          className="slider-title kinder-pulm white-layer banner-text"
                          data-animation-in="fadeInLeft"
                          data-animation-out="animate-out"
                        >
                          Best{" "}
                          <span className="kinder-orange">Kindergarten</span>
                        </h2>{" "}
                        <br />
                        <h2
                          className="slider-title kinder-pulm white-layer banner-text"
                          data-animation-in="fadeInLeft"
                          data-animation-out="animate-out"
                        >
                          <span className="kinder-blue">For Your</span> Kids
                        </h2>
                      </div>
                      <div className="slider-button banner-button">
                        <a
                          href="#"
                          className="readon hvr-ripple-out readon-cta uppercase"
                          data-animation-in="lightSpeedIn"
                          data-animation-out="animate-out"
                        >
                          Contact us Now
                        </a>
                        <a
                          href="#"
                          className="readon hvr-ripple-out readon-cta secondary uppercase"
                          data-animation-in="lightSpeedIn"
                          data-animation-out="animate-out"
                        >
                          Purchase Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="owl-item cloned active" style={{ marginRight: 0 }}>
            <div className="item active banner-one-card">
              <img
                src={blow1}
                alt=" Blossom "
              />
              <div className="slide-content banner-one-content">
                <div className="display-table">
                  <div className="display-table-cell">
                    <div className="container text-center">
                      <div className="sliter-title-text">
                        <h2
                          className="slider-title kinder-pulm white-layer banner-text"
                          data-animation-in="fadeInLeft"
                          data-animation-out="animate-out"
                        >
                          Best{" "}
                          <span className="kinder-orange">Kindergarten</span>
                        </h2>{" "}
                        <br />
                        <h2
                          className="slider-title kinder-pulm white-layer banner-text"
                          data-animation-in="fadeInLeft"
                          data-animation-out="animate-out"
                        >
                          <span className="kinder-blue">For Your</span> Kids
                        </h2>
                      </div>
                      <div className="slider-button banner-button">
                        <a
                          href="#"
                          className="readon hvr-ripple-out readon-cta uppercase"
                          data-animation-in="lightSpeedIn"
                          data-animation-out="animate-out"
                        >
                          Contact us Now
                        </a>
                        <a
                          href="#"
                          className="readon hvr-ripple-out readon-cta secondary uppercase"
                          data-animation-in="lightSpeedIn"
                          data-animation-out="animate-out"
                        >
                          Purchase Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="rs-kid-cta primary-bg pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-9">
              <div className="cta-text white-color text-md-left">
                <h2 className="margin-0 white-color">
                  Enroll Your Child In Our School
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="cta-button text-md-right">
                <Link
                  to="/play-group-nursery-near-borivali"
                  className="readon hvr-ripple-out readon-cta white uppercase"
                >
                  Enroll Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service start */}
      {/* ----------------our facilities------------- */}
      
      <div className="rs-services pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-md-30">
              <div className="single-service text-center">
                <div className="grid-style">
                  <div className="icon-style2">
                    <div className="shape pulm">
                    <i className="fi fi-br-child-head"/>
                        {/* <FaUsers /> */}
                      
                    </div>
                  </div>
                  <div className="service-text">
                    <h3 className="service-title">
                      <a className="kinder-pulm" href="#">
                        Play-Group
                      </a>
                    </h3>
                    <p className="service-desc">
                      We have expert teachers. Early childhood education begins
                      at home, and there are numerous books and television shows
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-md-30">
              <div className="single-service text-center">
                <div className="grid-style">
                  <div className="icon-style2">
                    <div className="shape blue">
                    <i className="fi fi-br-child-head"/>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3 className="service-title">
                      <a className="kinder-blue" href="#">
                        Nursery
                      </a>
                    </h3>
                    <p className="service-desc">
                      We always try to take creative lessions. Early childhood
                      education begins at home, and there are numerous books.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-sm-30">
              <div className="single-service text-center">
                <div className="grid-style">
                  <div className="icon-style2">
                    <div className="shape orange">
                    <i class="fi fi-rs-pan-food"/>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3 className="service-title">
                      <a className="kinder-orange" href="#">
                        Day Care
                      </a>
                    </h3>
                    <p className="service-desc">
                      We always try to follow active learning.Early childhood
                      education begins at home, and there are numerous books.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="single-service text-center">
                <div className="grid-style">
                  <div className="icon-style2">
                    <div className="shape green">
                      <i className="svg-icons flaticon-">
                        <FaEnvira />
                      </i>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3 className="service-title">
                      <a className="kinder-green" href="#">
                        Activity Club
                      </a>
                    </h3>
                    <p className="service-desc">
                      We have better environment for students. Early childhood
                      education begins at home, and there are numerous books.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* service End */}
      {/* About us start */}
      <div
        id="rs-about"
        className="rs-about align-items-center gray-bg sec-spacer"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 mb-md-30">
              <div className="about-img text-center">
                <div className="home-imagee"></div>
                <img src={homeAbout} alt="Blossom Playgroup" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 mpl-15 pl-40">
              <div className="about-tab style-1 extra-padding ">
                <div className="sec-title margin-0 text-left">
                  <h2 className="section-title primary-color">
                    <span className="kinder-blue">Welecome to</span>
                    Blossoms Preschool
                  </h2>
                  <h4 className="section-subtitle-right">
                    Information About Blossoms Preschool
                  </h4>
                  <p className="section-desc mb-15">
                  Welcome to Blossom Play-group Borivali, where every child's journey begins with joy and discovery. At Blossom Play-group Borivali, we are committed to nurturing young minds through engaging and playful learning experiences. Our dedicated team of educators creates a vibrant environment where children can truly blossom and thrive. Join us at Blossom Play-group Borivali to embark on an enriching educational adventure tailored to your child's unique needs, with an innovative curriculum designed to foster creativity, curiosity, and confidence. 
                  </p>
                  <p className="section-desc mb-15">
                  In our academy, we prioritize safety, comfort, and personalized attention for each child. Experience the magic of early childhood education with our preschool, where every day is an opportunity to learn, grow, and blossom together. Contact us today to schedule a visit and discover why families trust our institution for their child's first educational experience. 
                  </p>
                  {/* <div className="author-sign">
              <div className="name">
                Director - <span>John Tyler</span>
              </div>
              <img src="images/signature.png" alt="signature" />
            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About us end */}
      {/* Counter Up section Start */}
      {/* <div className="rs-counter counter-bg pt-100 pb-100">
        <div className="container">
          <div className="icon-image">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-6 mb-md-30">
                <div className="rs-counter-list">
                  <div className="counter-number plus">60</div>
                  <h3 className="counter-desc">Teachers</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6 mb-md-30">
                <div className="rs-counter-list blue-color">
                  <div className="counter-number plus">860</div>
                  <h3 className="counter-desc">Happy Kidds</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6 mb-sm-30">
                <div className="rs-counter-list orange-color">
                  <div className="counter-number plus">950</div>
                  <h3 className="counter-desc">Teaching Hours</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="rs-counter-list red-color">
                  <div className="counter-number plus">1000</div>
                  <h3 className="counter-desc">Meal Per Year</h3>
                </div>
              </div>
            </div>
            <div className="left-bottom-image animated pulse infinite">
              <img src={homeball} alt="Hand Image" />
            </div>
          </div>
        </div>
      </div> */}
       <Journey/>
      {/* Counter up section end */}


      {/* facilities start */}
      <div className="rs-services services-bg2 bg-overlay-white relative pt-100 pb-100">
  <div className="container">
    <div className="sec-title text-center">
      <h2 className="section-title mb-10 kinder-pulm">Our Facilities</h2>
      <h4 className="section-subtitle">Our Best Services For Your Kids</h4>
    </div>
    <div className="row">
      <div className="col-lg-4 col-md-6 col-sm-6 mb-30">
        <div className="single-service style-2">
          <div className="icon-style pulm">
            <i className="fi fi-rr-salad" />
          </div>
          <div className="service-text">
            <h3 className="service-title">
              <a className="kinder-pulm" href="#">
                Food Service
              </a>
            </h3>
            <p className="service-desc">
             In house Kitchen for providing healthy & hygienic food.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6 mb-30">
        <div className="single-service style-2">
          <div className="icon-style blue">
          <i className="fi fi-rr-bus-alt"/>
          </div>
          <div className="service-text">
            <h3 className="service-title">
              <a className="kinder-blue" href="#">
                Bus Service
              </a>
            </h3>
            <p className="service-desc">
              Own pickup & drop facility with lady take-care
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6 mb-30">
        <div className="single-service style-2">
          <div className="icon-style orange">
          <i class="fi fi-ts-book-open-cover"/>
          </div>
          <div className="service-text">
            <h3 className="service-title">
              <a className="kinder-orange" href="#">
                Library
              </a>
            </h3>
            <p className="service-desc">
             Educational books and toys library.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6 mb-30">
        <div className="single-service style-2">
          <div className="icon-style yellow">
          <i class="fi fi-rr-air-conditioner"/>
          </div>
          <div className="service-text">
            <h3 className="service-title">
              <a className="kinder-yellow" href="#">
                A/C class
              </a>
            </h3>
            <p className="service-desc">
             Well Equipped air conditioned class.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6 mb-30">
        <div className="single-service style-2">
          <div className="icon-style pink">
          <i class="fi fi-rs-tablet"/>
          </div>
          <div className="service-text">
            <h3 className="service-title">
              <a className="kinder-pink" href="#">
                Modern Technology
              </a>
            </h3>
            <p className="service-desc">
           Presentation by  Modern Technology and amchinery.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6 mb-30">
        <div className="single-service style-2">
          <div className="icon-style green">
          <i class="fi fi-tr-castle"/>
          </div>
          <div className="service-text">
            <h3 className="service-title">
              <a className="kinder-green" href="#">
                Playground 
              </a>
            </h3>
            <p className="service-desc">
              Huge playgroud for celebration & sports.            </p>
          </div>
        </div>
      </div>
      {/* <div className="viewmore-btn align-center mt-20">
        <a className="readon hvr-ripple-out uppercase" href="#">
          View All Services
        </a>
      </div> */}
    </div>
  </div>
</div>
{/* facilites end */}
     
      {/* Classes start */}
      <div id="rs-classes" className="rs-classes style-1 class-bg sec-spacer2">
        <div className="container">
          <div className="icon-image">
            <div className="sec-title text-center">
              <h2 className="section-title mb-10 kinder-pulm">
                Popular Activities
              </h2>
              <h4 className="section-subtitle">
                Our Weekly Classes For Your Kids
              </h4>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-50">
                <div className="classes-item classes-item2 blue-color">
                  <div className="classes-img">
                    <img
                      src={gym}
                      alt="Blossom "
                    />
                  </div>
                  <div className="classes-body">
                    <h4 className="classes-title">
                      <a href="#">Gymnastics</a>
                    </h4>
                    <p className="classes-subtitle">Primary, Math, History</p>
                    {/* <span className="classes-date kinder-pulm">
                      April 20, 2019
                    </span> */}
                    {/* <div className="classes-desc">
                      <p className="classes-txt">
                        <span>Age</span>{" "}
                        <span className="kinder-pulm">2-5 Years</span>
                      </p>
                      <p className="classes-txt">
                        <span>Size</span>{" "}
                        <span className="kinder-orange">15 Seats</span>
                      </p>
                      <p className="classes-txt margin-0">
                        <span>Price</span>{" "}
                        <span className="kinder-blue">$25/Day</span>
                      </p>
                    </div> */}
                    {/* <a
                      className="readon hvr-ripple-out small-readon kinder-pulm"
                      href="#"
                    >
                      Read More
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-50">
                <div className="classes-item classes-item2 blue-color">
                  <div className="classes-img">
                    <img
                      src={dance}
                      alt="Blossom Playgroup Borivali"
                    />
                  </div>
                  <div className="classes-body">
                    <h4 className="classes-title">
                      <a className="kinder-green" href="#">
                        Western Dance
                      </a>
                    </h4>
                    <p className="classes-subtitle">Primary, Math, History</p>
                    {/* <span className="classes-date kinder-blue">
                      April 20, 2019
                    </span>
                    <div className="classes-desc">
                      <p className="classes-txt">
                        <span>Age</span>{" "}
                        <span className="kinder-pulm">2-5 Years</span>
                      </p>
                      <p className="classes-txt">
                        <span>Size</span>{" "}
                        <span className="kinder-orange">15 Seats</span>
                      </p>
                      <p className="classes-txt margin-0">
                        <span>Price</span>{" "}
                        <span className="kinder-blue">$25/Day</span>
                      </p>
                    </div> */}
                    {/* <a
                      className="readon hvr-ripple-out small-readon kinder-blue"
                      href="#"
                    >
                      Read More
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-50">
                <div className="classes-item classes-item2 blue-color">
                  <div className="classes-img">
                    <img className="img-hand"
                      src={hand}
                      alt="Preschool"
                    />
                  </div>
                  <div className="classes-body">
                    <h4 className="classes-title">
                      <a className="kinder-pulm" href="#">
                        Hand Writting
                      </a>
                    </h4>
                    <p className="classes-subtitle">Primary, Math, History</p>
                    {/* <span className="classes-date kinder-blue">
                      April 20, 2019
                    </span>
                    <div className="classes-desc">
                      <p className="classes-txt">
                        <span>Age</span>{" "}
                        <span className="kinder-pulm">2-5 Years</span>
                      </p>
                      <p className="classes-txt">
                        <span>Size</span>{" "}
                        <span className="kinder-orange">15 Seats</span>
                      </p>
                      <p className="classes-txt margin-0">
                        <span>Price</span>{" "}
                        <span className="kinder-blue">$25/Day</span>
                      </p>
                    </div> */}
                    {/* <a
                      className="readon hvr-ripple-out small-readon kinder-blue"
                      href="#"
                    >
                      Read More
                    </a> */}
                  </div>
                </div>
              </div>
             
              <div className="col-lg-6 mb-50">
                <div className="classes-item classes-item2 blue-color">
                  <div className="classes-img">
                    <img
                      src={phonics}
                      alt="Blossom Playgroup Borivali Blossom Preschool"
                    />
                  </div>
                  <div className="classes-body">
                    <h4 className="classes-title">
                      <a className="kinder-green" href="#">
                    Phonics
                      </a>
                    </h4>
                    <p className="classes-subtitle">Primary, Math, History</p>
                    {/* <span className="classes-date kinder-green">
                      April 20, 2019
                    </span>
                    <div className="classes-desc">
                      <p className="classes-txt">
                        <span>Age</span>{" "}
                        <span className="kinder-pulm">2-5 Years</span>
                      </p>
                      <p className="classes-txt">
                        <span>Size</span>{" "}
                        <span className="kinder-orange">15 Seats</span>
                      </p>
                      <p className="classes-txt margin-0">
                        <span>Price</span>{" "}
                        <span className="kinder-blue">$25/Day</span>
                      </p>
                    </div> */}
                    {/* <a
                      className="readon hvr-ripple-out small-readon kinder-green"
                      href="#"
                    >
                      Read More
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-50">
                <div className="classes-item classes-item2 blue-color">
                  <div className="classes-img">
                    <img
                      src={art}
                      alt="Blossom Playgroup Borivali"
                    />
                  </div>
                  <div className="classes-body">
                    <h4 className="classes-title">
                      <a className="kinder-orange" href="#">
                        Art & Craft{" "}
                      </a>
                    </h4>
                    <p className="classes-subtitle">Primary, Math, History</p>
                    {/* <span className="classes-date kinder-orange">
                      April 20, 2019
                    </span>
                    <div className="classes-desc">
                      <p className="classes-txt">
                        <span>Age</span>{" "}
                        <span className="kinder-pulm">2-5 Years</span>
                      </p>
                      <p className="classes-txt">
                        <span>Size</span>{" "}
                        <span className="kinder-orange">15 Seats</span>
                      </p>
                      <p className="classes-txt margin-0">
                        <span>Price</span>{" "}
                        <span className="kinder-blue">$25/Day</span>
                      </p>
                    </div> */}
                    {/* <a
                      className="readon hvr-ripple-out small-readon kinder-orange"
                      href="#"
                    >
                      Read More
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="viewmore-btn text-center">
              <Link
                className="readon hvr-ripple-out readon-btn uppercase"
                to="/summer-camps"
              >
                View All Activities
              </Link>
            </div>
            <div className="right-bottom-image animated pulse infinite bottom-175">
              <img src={ball3} alt=" Borivali" />
            </div>
          </div>
        </div>
      </div>

      {/* Classes End */}
      {/* testimonial start */}
      <div className="rs-testimonial gray-bg pt-90 pb-170 mpb-70">
        <div className="container">
          <div className="sec-title text-center">
            <h2 className="section-title mb-10 kinder-pulm">Parents Review</h2>
            <h4 className="section-subtitle">
              What Our Kids Parent Say About Us
            </h4>
          </div>
          <div className="testimonial-vertical-carousel">
            <div className="row">
              <div className="col-lg-6 left-testimonial mb-md-30 pl-0">
                <div>
                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper">
                    <SwiperSlide>
                      <div
                        className="item testimonial-card kinder-orange-bg slick-slide slick-current slick-active slick-center"
                        data-slick-index={1}
                        aria-hidden="false"
                        //   style={{ width: 545 }}
                        tabIndex={-1}
                        role="option"
                        aria-describedby="slick-slide11"
                      >
                        <p>
                        Best preschool. Highly recommended to all. Staff and Management is very cooperative. Well planned and always gives positive vibes to parents .
                        </p>
                        <div className="author-info">
                          <h6 className="name">Sunil Yadav</h6>
                          <span>Kid Parent</span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="item testimonial-card kinder-blue-bg slick-slide slick-current slick-active slick-center"
                        data-slick-index={1}
                        aria-hidden="false"
                        //   style={{ width: 545 }}
                        tabIndex={-1}
                        role="option"
                        aria-describedby="slick-slide11"
                      >
                        <p>
                        This school is really the best preschool I have ever seen they celebrate all days with great efforts. Education is remarkable. Staff is so well trained and ofcourse the Principal is so kind, appreciable, respectable and cooperative. You will really love this school for your kids.
                        </p>
                        <div className="author-info">
                          <h6 className="name">Hardik Satra</h6>
                          <span>Kid Parent</span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="item testimonial-card kinder-green-bg slick-slide slick-current slick-active slick-center"
                        data-slick-index={1}
                        aria-hidden="false"
                        //   style={{ width: 545 }}
                        tabIndex={-1}
                        role="option"
                        aria-describedby="slick-slide11"
                      >
                        <p>
                        Blossom preschool offers best Education including quality courses with professional faculty. It's a perfect place for your child to learn and grow in a safe and nurturing environment
                        </p>
                        <div className="author-info">
                          <h6 className="name">Deep Satra</h6>
                          <span>Kid Parent</span>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="https://rstheme.com/products/html/kittuu/images/testimonial/2.jpg"
                  alt="image"
                  draggable="Blossom Playgroup"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* testimonial end */}

      {/*  gallery start */}
      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {/* {serviceData.map((v,i)=>{ */}
        {/* {galleryData.map((v,i)=>{
                return( */}
                {/* {PlayGroupData.map((v,i)=>(
                    
    )})} */}
    {PlayGroupData.map((v,i)=>(
        <SwiperSlide>
          {/* <> */}
          <div className="gallImg">
          <Image
          className="gallery"
            width=""
            height="250px"
            alt="Blossom Playgroup Blossom Preschool"
            src={v.img}
            preview={{
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                  },
                }
              ) => (
                <Space size={0} className="toolbar-wrapper">
                  {/* <DownloadOutlined onClick={onDownload} /> */}
                  <SwapOutlined rotate={90} onClick={onFlipY} />
                  <SwapOutlined onClick={onFlipX} />
                  <RotateLeftOutlined onClick={onRotateLeft} />
                  <RotateRightOutlined onClick={onRotateRight} />
                  <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                  <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                </Space>
              ),
            }}
          />
          </div>

          {/* </> */}
        </SwiperSlide>
    ))}
       
        {/* ) */}
        {/* })}    */}
      </Swiper>
      
      {/* gallery end */}
      <div className="rs-kid-cta primary-bg pt-50 pb-50">
  <div className="container">
    <div className="row">
      <div className="col-lg-9 col-md-9">
        <div className="cta-text white-color text-md-left">
          <h2 className="margin-0 white-color">
            See Our Kindergarten Photo Gallery!
          </h2>
        </div>
      </div>
      <div className="col-lg-3 col-md-3">
        <div className="cta-button text-md-right">
          <Link
            to="/preschool-borivali"
            className="readon hvr-ripple-out readon-cta white uppercase"
          >
            View Gallery
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>


     
    </>
  );
};

export default Home;
