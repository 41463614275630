import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../Assert/icons/final.png';
import '../Css/Header.css'; // Import your custom CSS styles here

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleScrolled = () => {
    const offset = window.scrollY;
    setScrolled(offset > 100);
  };

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen); // Toggle the state to open/close the Navbar
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrolled);
    return () => {
      window.removeEventListener('scroll', handleScrolled);
    };
  }, []); // Add and remove scroll event listener on component mount/unmount

  const handleNavItemClick = () => {
    setIsNavbarOpen(false); // Close the Navbar when a Nav.Link item is clicked
  };

  return (
    <Navbar expand="lg" className={`bg-body-tertiary ${scrolled ? 'sticky_nav1' : ''}`} expanded={isNavbarOpen}>
      <Container>
        <Link to="/">
          <Navbar.Brand>
            <img src={logo} alt="Blossom Playgroup" className="nav-logo" />
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" onClick={handleNavItemClick}>
            <NavItem to="/play-group-nursery-daycare-borivali" title="ABOUT US" />
            <NavItem to="/daycare-center-borivali" title="DAY CARE" />
            <NavItem to="/play-group-nursery-borivali" title="PLAYGROUP" />
            <NavItem to="/best-nursery-borivali" title="NURSERY" />
            <NavItem to="/kids-summer-camp-borivali-west" title="Summer Camps" />
            <NavItem to="/preschool-borivali" title="Gallery" />
            <NavItem to="/play-group-nursery-near-borivali" title="Contact Us" />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const NavItem = ({ to, title }) => (
  <Link to={to}>
    <Nav.Link href={to}>{title}</Nav.Link>
  </Link>
);

export default Header;
