import React, { useState,useLayoutEffect } from 'react';
import "../Css/Gallery.css";
import Breadcrumbs from "./Breadcrumbs";
import MetaTags from 'react-meta-tags';

import Tabs from "./Tabs";
import Items from "./Items";
import GalleryData from '../Components/GalleryData.jsx';
// import Bounce from 'react-reveal/Bounce';
import Footer from '../Components/Footer.jsx';
// import { MetaTags } from 'react-meta-tags';
import gallery1 from "../Assert/bullockcart/WhatsApp-Image-2024-04-27-at-12.44.22.avif"
import gallery2 from "../Assert/gallery/bloss-1.jpeg"
import gallery3 from "../Assert/gallery/bloss-gal.jpeg"
import GalleryP from './GalleryP.jsx';
const Gallery = () => {


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
      });

    const [data, setData] = useState(GalleryData)

    // Store Category in CategoryData 
    const categoryData = GalleryData.map((value) => {
        return value.Category
    });
    ////////////

    const tabsData = ["all", ...new Set(categoryData)];


    // for all categoryData set 
    const filterCategory = (Category) => {
        if (Category == "all") {
            setData(GalleryData)
            return;
        }
        const filteredData = GalleryData.filter((value) => {
            return value.Category == Category;
        })

        setData(filteredData);

    }


    return (
        <>

<MetaTags>
      <title>Preschool in Borivali | Blossom Preschool</title>
        <meta title="Preschool in Borivali | Blossom Preschool" />
        <meta
          name="description"
          content="Blossom Preschool in Borivali offers a nurturing environment for early learners, fostering growth and development through engaging activities.."
        />
        <meta
          name="keywords"
          content="Blossom Play-group Borivali,Play Group Nursery and Daycare in Borivali,Daycare Center in Borivali,Play Group Nursery in Borivali,Best Nursery in Borivali,Preschool in Borivali,Kids Summer Camp in Borivali West,Play Group Nursery near Borivali"
        />
        <link rel="canonical" href="https://justmirrorsalon.in/top-salon-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Preschool in Borivali | Blossom Preschool" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://justmirrorsalon.in/top-salon-andheri"/>
        <meta property="og:description" content="Blossom Preschool in Borivali offers a nurturing environment for early learners, fostering growth and development through engaging activities." />
        <meta property="og:image" content="https://justmirrorsalon.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
 {/* ========= SEO ========== */}

 {/* <MetaTags>
        <title>Top Hall In Sion | Gokul Events</title>
        <meta
          name="description"
          content="Elevate your events to new heights with the Top Hall in Sion. Explore our premier venue for unforgettable gatherings and celebrations."
        />

        <meta property="og:title" content="Top Hall in Sion | Gokul Events" />
        <meta
          property="og:description"
          content="Elevate your events to new heights with the Top Hall in Sion. Explore our premier venue for unforgettable gatherings and celebrations."
        />
        <meta
          name="keywords"
          content="Hall In Sion,
          Party Hall In Sion,
          Banquet in Sion,
          Wedding Banquet In Sion,
          Best Banquet In Sion,
          Party Banquet In Sion,
          Gokul Hall,
          auditorium In Sion,
          AC Hall In Sion,
          AC Wedding Halls In Sion,
          Roof Top Hall In Sion"
        />
        <link rel="canonical" href="https://gokulevents.in/top-hall-sion" />
        <meta name="google-site-verification" content="VBB-V05nLFwZS9pwXxrINkZ79RHxEazT6XA_NDNapFA" />
      </MetaTags> */}

      {/* ======== SEO end ======= */}
            {/* ///////////////////////////////// section 1 ////////////////////////////////// */}
            <Breadcrumbs pageName="Preschool in Borivali" slugs="Gallery" />
          

            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <div className='Gallery-Section-2'>
               <GalleryP/>
            </div>
                 <div className='hidden-content'>
                 Welcome to Preschool in Borivali where learning begins with love and blooms with imagination! Nestled in the heart of Borivali, we are proud to be your neighborhood preschool, dedicated to nurturing young minds in a safe, supportive, and stimulating environment.
At Blossom Preschool in Borivali we understand that early childhood education lays the foundation for a lifetime of learning. That's why we offer a holistic approach to preschool education, focusing on the social, emotional, cognitive, and physical development of each child. Through play-based learning activities, interactive games, and hands-on experiences, we ignite curiosity, spark creativity, and foster a love for learning in every child who walks through our doors.
Our team of experienced educators is committed to providing personalized attention to every child, ensuring that they feel valued, respected, and empowered to explore their interests and express themselves freely. With small class sizes and a warm, nurturing atmosphere, Blossom Playschool creates the perfect setting for children to grow, learn, and blossom into confident, independent individuals.
As the premier preschool in Borivali, we take pride in our strong sense of community and our partnership with families. We believe that parents are our most valuable partners in a child's education journey, and we strive to involve them in every step of their child's learning and development.
Experience the magic of early childhood education at Blossom Playschool – where every child's potential is nurtured, and every day is an adventure in learning! Visit us today to learn more about our programs and enroll your child in the best preschool in Borivali.


                 </div>
            {/* <Footer/> */}
        </>
    )
}

export default Gallery