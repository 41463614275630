import React from "react";
import logo from "../Assert/icons/final.png";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import {Link} from "react-router-dom";


const Footer = () => {
  return (
    <>
      {/* Footer start */}
      <footer id="rs-footer" className="rs-footer footer-2 footer-bg2  pt-5">
        {/* Footer Top */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="footer-contact">
                  <div className="footer-logo-part">
              <img src={logo} alt="Footer Logo" />
            </div>
                 
                </div>
              </div>
              <div className="col-lg-3 col-md-12 mb-md-30">
                <h4 className="footer-title">Useful Links</h4>
                <ul className="sitemap-widget clearfix m-0 p-0">
                  <li className="active">
                    <span href="index.html">
                      <i className="fa fa-angle-"> 
                      <IoIosArrowForward />

                       </i>
                       <Link to="/play-group-nursery-daycare-borivali">
                       
                      Home
                       </Link>
                    </span>
                  </li>
                  <li>
                    <Link to="/play-group-nursery-daycare-borivali">
                    <i className="fa fa-angle-"> 
                      <IoIosArrowForward />

                       </i>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/play-group-nursery-daycare-borivali">
                    <i className="fa fa-angle-"> 
                      <IoIosArrowForward />
                       </i>
                      DayCare
                    </Link>
                  </li>
                  <li>
                    <Link to="/best-nursery-borivali">
                    <i className="fa fa-angle-"> 
                      <IoIosArrowForward />
                       </i>
                      Nursery
                    </Link>
                  </li>
                  <li>
                    <Link to="/play-group-nursery-borivali">
                    <i className="fa fa-angle-"> 
                      <IoIosArrowForward />
                       </i>
                      Playgroup
                    </Link>
                  </li>
                  <li>
                    <Link to="/kids-summer-camp-borivali-west">
                    <i className="fa fa-angle-"> 
                      <IoIosArrowForward />
                       </i>
                      Summer Camp
                    </Link>
                  </li>
                  <li>
                    <Link to="/preschool-borivali">
                    <i className="fa fa-angle-"> 
                      <IoIosArrowForward />

                       </i>
                      Gallery
                    </Link>
                  </li>
                  {/* <li>
                    <a href="event.html">
                      <i className="fa fa-angle-right" />
                      Event
                    </a>
                  </li>
                  <li>
                    <a href="blog.html">
                      <i className="fa fa-angle-right" aria-hidden="true" />
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="blog-details.html">
                      <i className="fa fa-angle-right" aria-hidden="true" />
                      Blog Details
                    </a>
                  </li>
                  <li>
                    <a href="teacher.html">
                      <i className="fa fa-angle-right" aria-hidden="true" />
                      Teachers
                    </a>
                  </li>
                  <li>
                    <a href="teacher-single.html">
                      <i className="fa fa-angle-right" aria-hidden="true" />
                      Teach Single
                    </a>
                  </li> */}
                  <li>
                    {/* <a href="contact.html"> */}
                    <i className="fa fa-angle-"> 
                      <IoIosArrowForward />

                       </i>
                       <Link to="/play-group-nursery-near-borivali">
                       
                      Contact
                       </Link>
                    {/* </a> */}
                  </li>
                  {/* <li>
                    <a href="error.html">
                      <i className="fa fa-angle-right" aria-hidden="true" />
                      Error 404
                    </a>
                  </li> */}
                </ul>
              </div>
              {/* <div className="col-lg-3 col-md-12 mb-md-30">
                <h4 className="footer-title">Recent Posts</h4>
                <div className="recent-post-widget">
                  <div className="post-item">
                    <div className="post-date">
                      <span>28</span>
                      <span>June</span>
                    </div>
                    <div className="post-desc">
                      <div className="post-title">
                        <a href="#">Welcome to Responsive Retina Theme</a>
                      </div>
                      <span className="post-category">Design</span>
                    </div>
                  </div>
                  <div className="post-item">
                    <div className="post-date">
                      <span>28</span>
                      <span>June</span>
                    </div>
                    <div className="post-desc">
                      <div className="post-title">
                        <a href="#">Welcome to Responsive Retina Theme</a>
                      </div>
                      <span className="post-category">Design</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-12">
                <div className="footer-contact">
                  {/* <div className="footer-logo-part">
              <img src={logo} alt="Footer Logo" />
            </div> */}
                  <div className="footer-contact-part">
                    <div className="contact-item mb-15">
                      <div className="icon-part kinder-pulm">
                        <i className="fa fa-map-" >
                        <FaLocationDot />
                         </i>
                      </div>
                      <div className="address-part">
                      <span> <span>
                        Branch I - 1 to 4, Kora Kendra New Bldg., Shimpoli Road,.
                          Borivali West, Mumbai - 400 092
                        </span>
                      </span>   </div>
                    </div>
                    <div className="contact-item mb-15">
                      <div className="icon-part kinder-pulm">
                        <i className="fa fa-map-" >
                        <FaLocationDot />
                         </i>
                      </div>
                      <div className="address-part">
                      <span>  Branch II - 1 to 6 Hemlata Apt., Jairaj Nagar, <br/>Borivali West, Mumbai - 400 091
                      </span>   </div>
                    </div>
                    <div className="contact-item mb-15">
                      <div className="icon-part rotate-icon kinder-orange">
                        <i className="fa fa-"> 
                        <IoMdCall />
                          </i>
                      </div>
                      <div className="number-part">
                        <a href="tel:+919324151344">+91 9321317161/
+91  9324675168</a>
                        {/* <br />
                        <a href="tel:+919867228513">
                        +91 9867228513
                        </a> */}
                      </div>
                    </div>
                    <div className="contact-item">
                      <div className="icon-part kinder-blue">
                        <i className="fa fa-"> 
                        <MdEmail />

                         </i>
                      </div>
                      <div className="email-part">
                        <a href="mailto:blossomsps19@gmail.com">
                        blossoms4predcc@gmail.com
                        </a>
                        <br />
                        {/* <a href="#">www.yourcompany.com</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12">
                <div className="footer-contact">
                  {/* <div className="footer-logo-part">
              <img src={logo} alt="Footer Logo" />
            </div> */}
                 <div style={{width:"100%"}}>
                 <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.5138133109945!2d72.841555!3d19.2164263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b73b475d6835%3A0xf2d1dbbfd6a7ca04!2sBlossoms%20Preschool%20%26%20Daycare%20-%20Playgroup%20%7C%20Nursery%20%7C%20Daycare%20Centre%20%7C%20Preschool%20in%20Borivali!5e0!3m2!1sen!2sin!4v1713528028780!5m2!1sen!2sin"
  width={300}
  height={250}
  style={{ borderRadius:"5px" }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="copyright">
              <p>
                © 2024 <a href="#"> Blossoms Play Group And Nursery</a>. All Rights Reserved.
              </p>
              Design by <span className="shree">shree krishna digital marketing</span>
            </div>
          </div>
        </div>
      </footer>

      {/* Footer end */}
    </>
  );
};

export default Footer;
