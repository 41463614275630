import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import $ from "jquery"
import "../Css/jour.css";
import girl from "../Assert/girls122.png"
import boyl from "../Assert/71wMc0YCtDL-removebg-preview.png"

function Journey() {
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        return newProgress;
      });
    }, 30); // Adjust the interval duration for smoother progress

    // Simulate stopping progress at 85%
    setTimeout(() => {
      clearInterval(interval);
      setLoadingProgress(85);
    }, 2500); // Adjust the duration to match your actual loading time

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  /////////////////////////////////////////////////////////////////
  const progressBars = [
    { title: "Happy Kid's", percentage: "90%", endProgress:'10000'  },
    { title: "Happy Parent's", percentage: "95%", endProgress:'20000' },
    // { title: "UI/UX", percentage: "50%" },
    // { title: "JavaScript", percentage: "70%" },
  ];

  useEffect(() => {
    animateProgressBars();
  }, []);

  const animateProgressBars = () => {
    $(".progress-bar").each(function (){
      const progressBar = $(this);
      const progressFill = progressBar.find(".progress-fill");
      const progressNumber = progressBar.find(".progress-number .percent");

      const percentage = progressFill.data("percentage");

      // Animate progress bar width
      progressFill.animate({ width: percentage }, 2000);

      // Animate progress number
      $({ countNum: 0 }).animate(
        { countNum: parseInt(percentage) },
        {
          duration: 5000,
          easing: "linear",
          step: function () {
            progressNumber.text(Math.floor(this.countNum) + "%");
          },
        }
      );
    });
  };

  ///////////////////////////////////////////////////////////////

  return (
    <>
      <section className="bgc-deg">
        <div className="container">
          
          <div className="row">
            <div className="col-2">
              <div className="kid-imgg ">
                <div className="boy12">
                </div>
                <img
                  src={ girl}
                  alt="Blossom Playgroup Borival"
                />
              </div>
            </div>
            <div className="col-8">
              <div className="container">
              <div className="sec-title text-center">
                <div className="tit-content">
      <h2 className="section-title mb-10 kinder-pulm">Journey 25 Years</h2>
      <h4 className="section-subtitle">Our Best Services For Your Kids</h4>
    </div>
    </div>
                {progressBars.map((bar, index) => (
                  <div className="progressbar-item" key={index}>
                    
                    <h4 className="progress-title">{bar.title}</h4>
                  <div className="count-bar">
              
      <CountUp delay={2} end={bar.endProgress}></CountUp> {"+"}
   
                  </div>
                  {/* <div className="count-bar">
                  <CountUp start={0} end={10000}>
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          <span onClick={start}>+</span>
                        </div>
                      )}
                    </CountUp>
                  </div> */}
                    <div className="progress-bar">
                      {/* <div className="progress-number">
                        <div className="progress-number-mark">
                          <span className="percent">0%</span>
                          <span className="down-arrow"></span>
                        </div>
                      </div> */}
                      <div className="progress-bg">
                        <div
                          className="progress-fill"
                          style={{ width: "0%", transition: "width 2s ease" }}
                          data-percentage={bar.percentage}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-2">
              <section>
                <div className="container">
              <div className="kid-imgg ">
              <img
                src={boyl}
                alt=" Blossom Preschool"
              />
              </div>
              </div>
            </section>
            </div>
          </div>
        </div>
        
      </section>
    </>
  );
}

export default Journey;
